import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {addEventTag, deleteEventTag, getEventTags, updateEventTagDetail, setAlert} from "../../../../redux/action/action";
import {
    CButton,
    CCard,
    CCardBody,
    CForm,
    CFormInput,
    CFormLabel,
    CModal,
    CModalBody,
    CModalHeader,
    CModalTitle,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import {cilPlus} from "@coreui/icons";
import {useNavigate} from "react-router-dom";
import DataTable from "react-data-table-component";
import {columns, applyFilters} from "./FilterUtils";
import {users} from "constants";

const EventTags = () => {
    document.title = "Event Tags | DoubleSpot Business";
    document.querySelector('meta[name="description"]').setAttribute(
        "content",
        "View and manage your event tags with DoubleSpot's business dashboard."
    );

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userDetail = useSelector(state => state.Apis.userDetail);
    const eventTags = useSelector(state => state.Apis.eventTags);
    const updatedEventTag = useSelector(state => state.Apis.updatedEventTag);
    const updateEventTagSuccess = useSelector(state => state.Apis.updateEventTagSuccess);
    const addEventTagSuccess = useSelector(state => state.Apis.addEventTagSuccess);

    const [displayedEventTags, setDisplayedEventTags] = useState([]);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [editModalEventTag, setEditModalEventTag] = useState(null);
    const [editFormData, setEditFormData] = useState({});
    const [filters, setFilters] = useState({});

    const updateFilters = (event) => {
        setFilters({...filters, [event.target.name]: event.target.value});
    };

    const handleEditButtonClick = (eventType) => {
        setEditModalEventTag(eventType);
        setEditModalVisible(true);
    }

    const handleAddButtonClick = () => {
        setEditModalEventTag(null);
        setEditModalVisible(true);
    }

    const updateEditForm = (event) => {
        setEditFormData({...editFormData, [event.target.name]: event.target.value});
    }

    const handleEditFormSubmit = (event) => {
        event.preventDefault();
        if (editModalEventTag) {
            if (window.confirm("Are you sure you want to update this event tag? The change in details will be applied to every manager in your business.")) {
                dispatch(updateEventTagDetail({
                    id: editModalEventTag.id,
                    ...editFormData,
                }))
            }
        } else {
            if (window.confirm("Are you sure you want to add this event tag? The event tag will be visible to every manager in your business.")) {
                dispatch(addEventTag(editFormData));
            }
        }
    }

    const handleDeleteButtonClick = (id) => {
        if (window.confirm("Are you sure you want to delete this event tag?")) {
            dispatch(deleteEventTag({id}));
        }
    }

    useEffect(() => {
        dispatch(getEventTags());
        if (updateEventTagSuccess || addEventTagSuccess) {
            setEditModalVisible(false);
        }
    }, [userDetail?.id, updatedEventTag]);

    useEffect(() => {
        if (eventTags) {
            setDisplayedEventTags(applyFilters(eventTags, filters));
        }
    }, [eventTags, filters]);

    useEffect(() => {
        if (userDetail.role) {
            if (userDetail.role !== users.ROLE_VENUE_OWNER && !userDetail.manager?.editEventAccess) {
                dispatch(setAlert('You do not have the permissions to view the event tags.','error'));
                navigate("/business/reservations/calendar");
            }
        }
    }, [userDetail]);

    return (
        <div class="p-4 w-100">
            <CCard>
                <CCardBody className="p-4">
                    <div className="flex-row justify-content-between align-items-end">
                        <div>
                            <h2>Event Tags</h2>
                            <p className="modal-subtitle mb-0">The event tag settings will apply to every manager in
                                your business.</p>
                        </div>
                        <div>
                            <CButton color="light" onClick={handleAddButtonClick}>
                                <CIcon icon={cilPlus} className="primary-color"/> &nbsp; Add Event Tag
                            </CButton>
                        </div>
                    </div>
                    <hr/>
                    <div class="flex-row justify-content-between align-items-start">
                        <div class="flex-row flex-wrap align-items-center">
                            <div class="pe-2 pb-3">
                                <CFormInput name="search" placeholder="Search..." onChange={updateFilters}/>
                            </div>
                        </div>
                    </div>
                    <DataTable
                        columns={columns(handleEditButtonClick, handleDeleteButtonClick)}
                        data={displayedEventTags}
                        pagination
                    />
                </CCardBody>
            </CCard>
            <CModal backdrop="static" visible={editModalVisible} onClose={() => setEditModalVisible(false)}>
                <CModalHeader>
                    <CModalTitle>{editModalEventTag ? "Edit Event Type" : "Add Event Type"}</CModalTitle>
                </CModalHeader>
                <CModalBody className="business-modal-body">
                    <CForm onSubmit={handleEditFormSubmit}>
                        <CFormLabel htmlFor="name">Name</CFormLabel>
                        <div className="pb-3">
                            <CFormInput required type="text" name="name" onChange={updateEditForm}
                                        defaultValue={editModalEventTag?.name}/>
                        </div>
                        <CButton color="primary" type="submit">Submit</CButton>
                    </CForm>
                </CModalBody>
            </CModal>
        </div>
    );
}

export default EventTags;

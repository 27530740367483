import {dateToIsoString, formatDate} from "../../../../Utils/DateTimeUtils";
import {CButton} from "@coreui/react";

const leadsColumns = [
    {
        name: 'Name',
        selector: row => row.form_submission?.name || "N/A",
        width: "200px",
        wrap: true,
        sortable: true,
        reorder: true,
    },
    {
        name: 'Email',
        selector: row => row.form_submission?.email || "N/A",
        width: "250px",
        wrap: true,
        sortable: true,
        reorder: true,
    },
    {
        name: 'Phone',
        selector: row => row.form_submission?.phone || "N/A",
        width: "150px",
        wrap: true,
        sortable: true,
        reorder: true,
    },
];

const commonColumns = (onViewButtonClick) => [
    {
        name: 'Time',
        selector: row => new Date(row.created_at),
        format: row => {
            const [date, time] = row.created_at.split('T');
            return formatDate(date, time);
        },
        width: "300px",
        sortable: true,
        reorder: true,
    },
    {
        name: 'Messages',
        selector: row => row.messages.length,
        width: "125px",
        wrap: true,
        sortable: true,
        reorder: true,
    },
    {
        name: '',
        cell: row => (
            <div class="py-2">
                <CButton color="dark" variant="outline" onClick={() => onViewButtonClick(row)}>
                    View
                </CButton>
            </div>
        ),
        minWidth: "100px",
        right: true,
    }
];

export const columns = (displayLeads, onViewButtonClick) => [
    ...(displayLeads ? leadsColumns : []),
    ...commonColumns(onViewButtonClick)
];

export const filterCategories = [
    {
        name: 'Name',
        category: 'name',
        type: 'exact',
    },
    {
        name: 'Email',
        category: 'email',
        type: 'exact',
    },
    {
        name: 'Phone',
        category: 'phone',
        type: 'exact',
    },
    {
        name: 'Date',
        category: ['start_date', 'end_date'],
        type: 'range',
    },
    {
        name: 'Messages',
        category: ['min_length', 'max_length'],
        type: 'range',
    },
];

export const applyFilters = (conversations, filters) => {
    return conversations.filter(entry => {
        if (filters.search) {
            const searchTerm = filters.search.toLowerCase();
            if (!(
                entry.form_submission?.email.toLowerCase().includes(searchTerm) ||
                entry.form_submission?.name.toLowerCase().includes(searchTerm)
            )) {
                return false;
            }
        }

        if (filters.name) {
            const searchTerm = filters.name.toLowerCase();
            if (!entry.form_submission?.name.toLowerCase().includes(searchTerm)) return false;
        }

        if (filters.email) {
            const searchTerm = filters.email.toLowerCase();
            if (!entry.form_submission?.email.toLowerCase().includes(searchTerm)) return false;
        }

        if (filters.phone) {
            const searchTerm = filters.phone.toLowerCase();
            if (!entry.form_submission?.phone.includes(searchTerm)) return false;
        }

        const createdDate = dateToIsoString(new Date(entry.created_at));
        if (filters.start_date) {
            if (createdDate < filters.start_date) return false;
        }

        if (filters.end_date) {
            if (createdDate > filters.end_date) return false;
        }

        if (filters.min_length) {
            if (entry.messages.length < filters.min_length) return false;
        }

        if (filters.max_length) {
            if (entry.messages.length > filters.max_length) return false;
        }

        return true;
    });
};

import React, {useEffect, useState} from "react";
import BannerSection from "../Layout/Home/BannerSection";
import JoinUs from "../Layout/Home/JoinUs";
import RecommendedVenues from "../Layout/Home/RecommendedVenues";
import {useDispatch, useSelector} from "react-redux";
import {recomendedVenues} from "../../redux/action/action";

const Home = () => {
    document.title = "Home | DoubleSpot";
    document.querySelector('meta[name="description"]').setAttribute(
        "content",
        "Welcome to DoubleSpot, where venues strive & events come alive!"
    );

    const dispatch = useDispatch()
    const venuesData = useSelector(state => state.Apis.list);
    const totalCount = useSelector(state => state.Apis.totalCount);
    const { name, role } = useSelector(state => state.Login.userDetail);

    const [venues, setVenues] = useState([]);

    const GetRecommendedVenues = () => {
        let sendData = {
            page: 1,
        };
        if(localStorage.getItem("userId") !== "undefined") {
            sendData.userId =  localStorage.getItem("userId");
        }
        dispatch(recomendedVenues(sendData));
    }

    useEffect(() => {
        setVenues(venuesData);
    }, [venuesData, totalCount]);

    useEffect(() => {
        GetRecommendedVenues();
    }, [role, name]);

    return (
        <>
            <BannerSection showSearchBar/>
            {/* <WeofferSectionHome /> */}
            <>
                <section className="section_how_work pt-5">
                    <div className="container">
                        <h2 className="section_heading mb-3">
                            How does it work?
                        </h2>
                        <div className="row border-top border-bottom py-4 gy-4">
                            <div className="col-lg-4 col-md-6">
                                <div className="card border-0">
                                    <img src="../../../assets/images/search_img.png" alt="Doublespot.com" />
                                    <h3>Step 1:</h3>
                                    <h3>Search a Location</h3>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="card border-0">
                                    <img src="../../../assets/images/hand.png" alt="Doublespot.com" />
                                    <h3>Step 2:</h3>
                                    <h3>Book a Space</h3>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-6">
                                <div className="card border-0">
                                    <img src="../../../assets/images/partypopper.png" alt="Doublespot.com" />
                                    <h3>Step 3:</h3>
                                    <h3>Host an Event</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
            <div className="pt-4">
                <RecommendedVenues
                    showHeading
                    dispatch={dispatch}
                    venues={venues}
                    setVenues={setVenues}
                    removeVenue={false}
                />
            </div>
            <JoinUs />
        </>
    )

}


export default Home;
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {addEventType, deleteEventType, getEventTypes, setAlert, updateEventTypeDetail} from "../../../redux/action/action";
import {
    CButton,
    CCard,
    CCardBody,
    CForm,
    CFormInput,
    CFormLabel,
    CModal,
    CModalBody,
    CModalHeader,
    CModalTitle,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow
} from "@coreui/react";
import {CirclePicker} from "react-color";
import CIcon from "@coreui/icons-react";
import {cilPlus} from "@coreui/icons";
import {useNavigate} from "react-router-dom";
import {users} from "constants";

const EventTypes = () => {
    document.title = "Event Types | DoubleSpot Business";
    document.querySelector('meta[name="description"]').setAttribute(
        "content",
        "View and manage your event types with DoubleSpot's business dashboard."
    );

    const eventTypeColors = [
        '#888888',
        '#d50201',
        '#8f24aa',
        '#098143',
        '#3f51b5',
        '#f5521d',
        '#f5a70a',
        '#049be5',
    ];

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userDetail = useSelector(state => state.Apis.userDetail);
    const businessEventTypes = useSelector(state => state.Apis.eventTypes);
    const updatedEventType = useSelector(state => state.Apis.updatedEventType);
    const [eventTypes, setEventTypes] = useState([]);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [editModalEventType, setEditModalEventType] = useState(null);
    const [editFormData, setEditFormData] = useState({});

    const handleEditButtonClick = (eventType) => {
        setEditModalEventType(eventType);
        setEditFormData({
            color: eventType.color,
        });
        setEditModalVisible(true);
    }

    const handleAddButtonClick = () => {
        setEditFormData({
            color: 0,
        });
        setEditModalEventType(null);
        setEditModalVisible(true);
    }

    const handleColorPickerChange = (color) => {
        setEditFormData({
            ...editFormData,
            color: eventTypeColors.indexOf(color.hex)
        });
    }

    const updateEditForm = (event) => {
        setEditFormData({...editFormData, [event.target.name]: event.target.value});
    }

    const handleEditFormSubmit = (event) => {
        event.preventDefault();
        if (editModalEventType) {
            if (window.confirm("Are you sure you want to update this event type? The change in details will be applied to every manager in your business.")) {
                dispatch(updateEventTypeDetail({
                    id: editModalEventType.id,
                    ...editFormData
                }));
            }
        } else {
            if (window.confirm("Are you sure you want to add this event type? The event type will be visible to every manager in your business.")) {
                dispatch(addEventType(editFormData));
            }
        }
    }

    const handleDeleteButtonClick = (id) => {
        if (window.confirm("Are you sure you want to delete this event type? Events with this event type will become uncategorized for every manager in your business.")) {
            dispatch(deleteEventType({id}));
        }
    }

    useEffect(() => {
        if (userDetail?.role) {
            let businessId;
            if (userDetail?.role === users.ROLE_VENUE_OWNER) {
                businessId = userDetail?.id;
            } else if (userDetail.role === users.ROLE_VENUE_MANAGER) {
                businessId = userDetail?.bussnessId;
            }
            dispatch(getEventTypes({
                businessId
            }));
        }
        setEditModalVisible(false);
    }, [userDetail?.id, updatedEventType]);

    useEffect(() => {
        setEventTypes(businessEventTypes);
    }, [businessEventTypes]);

    useEffect(() => {
        if (userDetail.role) {
            if (userDetail.role !== users.ROLE_VENUE_OWNER && !userDetail.manager?.editEventAccess) {
                dispatch(setAlert('You do not have the permissions to view the event types.','error'));
                navigate("/business/reservations/calendar");
            }
        }
    }, [userDetail]);

    return (
        <div class="p-4 w-100">
            <CCard>
                <CCardBody className="p-4">
                    <div className="flex-row justify-content-between align-items-end">
                        <div>
                            <h2>Event Types</h2>
                            <p className="modal-subtitle mb-0">The event type settings will apply to every manager in
                                your business.</p>
                        </div>
                        <div>
                            <CButton color="light" onClick={handleAddButtonClick}>
                                <CIcon icon={cilPlus} className="primary-color"/> &nbsp; Add Event Type
                            </CButton>
                        </div>
                    </div>
                    <hr/>
                    <CTable align="middle" className="mb-0 border" hover style={{tableLayout: "fixed"}}>
                        <CTableHead className="text-nowrap">
                            <CTableRow>
                                <CTableHeaderCell className="bg-body-tertiary" style={{width: "1%"}}/>
                                <CTableHeaderCell className="bg-body-tertiary" style={{width: "6%"}}>Color</CTableHeaderCell>
                                <CTableHeaderCell className="bg-body-tertiary" style={{width: "75%"}}>Name</CTableHeaderCell>
                                <CTableHeaderCell className="bg-body-tertiary" style={{width: "7%"}}/>
                                <CTableHeaderCell className="bg-body-tertiary" style={{width: "10%"}}/>
                                <CTableHeaderCell className="bg-body-tertiary" style={{width: "1%"}}/>
                            </CTableRow>
                        </CTableHead>
                        <CTableBody>
                            {eventTypes.map((eventType, index) => (
                                <CTableRow v-for="item in tableItems" key={index}>
                                    <CTableDataCell/>
                                    <CTableDataCell>
                                        <div className="event-type-color-circle"
                                             style={{backgroundColor: eventTypeColors[eventType.color]}}/>
                                    </CTableDataCell>
                                    <CTableDataCell>
                                        <div>{eventType?.name}</div>
                                    </CTableDataCell>
                                    <CTableDataCell className="text-center">
                                        <CButton color="dark" variant="outline" onClick={() => handleEditButtonClick(eventType)}>Edit</CButton>
                                    </CTableDataCell>
                                    <CTableDataCell className="text-center">
                                        <CButton color="danger" className="white" onClick={() => handleDeleteButtonClick(eventType.id)}>Delete</CButton>
                                    </CTableDataCell>
                                    <CTableDataCell/>
                                </CTableRow>
                            ))}
                        </CTableBody>
                    </CTable>
                </CCardBody>
            </CCard>
            <CModal backdrop="static" visible={editModalVisible} onClose={() => setEditModalVisible(false)}>
                <CModalHeader>
                    <CModalTitle>{editModalEventType ? "Edit Event Type" : "Add Event Type"}</CModalTitle>
                </CModalHeader>
                <CModalBody className="business-modal-body">
                    <CForm onSubmit={handleEditFormSubmit}>
                        <CFormLabel htmlFor="title">Name</CFormLabel>
                        <div className="pb-3">
                            <CFormInput required type="text" name="name" onChange={updateEditForm} defaultValue={editModalEventType?.name}/>
                        </div>
                        <CFormLabel htmlFor="title">Color</CFormLabel>
                        <div className="pb-3">
                            <CirclePicker
                                width={400}
                                color={eventTypeColors[editFormData.color ? editFormData.color : 0]}
                                onChangeComplete={handleColorPickerChange}
                                colors={eventTypeColors.slice(1)}
                            />
                        </div>
                        <CButton color="primary" type="submit">Submit</CButton>
                    </CForm>
                </CModalBody>
            </CModal>
        </div>
    );
}

export default EventTypes;

import React, {useEffect, useState} from "react";
import {
    CButton,
    CCard,
    CCardBody,
} from "@coreui/react";
import DataTable from "react-data-table-component";
import {useDispatch, useSelector} from "react-redux";
import {calenderData, getEventTags, getEventTypes, getProviderVenueListing} from "../../../../redux/action/action";
import CIcon from "@coreui/icons-react";
import {cilCloudDownload} from "@coreui/icons";
import FilterModal from "./FilterModal";
import {columns, filterCategories, applyFilters} from "./FilterUtils";
import DownloadModal from "./DownloadModal";
import Filters from "../../../components/Filters";
import {users} from "constants";

const ReservationTable = () => {
    document.title = "Reservation Table | DoubleSpot Business";
    document.querySelector('meta[name="description"]').setAttribute(
        "content",
        "Organize your venues' booking history with DoubleSpot's business dashboard."
    );

    const dispatch = useDispatch();

    const bookings = useSelector(state => state.Apis.currentBooking);
    const venueListings = useSelector(state => state.Apis.providerVenuesListing);
    const userDetail = useSelector(state => state.Login.userDetail);
    const businessEventTypes = useSelector(state => state.Apis.eventTypes);
    const eventTags = useSelector(state => state.Apis.eventTags);

    const [venues, setVenues] = useState([]);
    const [eventTypes, setEventTypes] = useState([]);
    const [allEventTags, setALlEventTags] = useState([]);
    const [displayedBookings, setDisplayedBookings] = useState([]);
    const [filters, setFilters] = useState({});
    const [filterModalVisible, setFilterModalVisible] = useState(false);
    const [filterCount, setFilterCount] = useState(0);
    const [downloadModalVisible, setDownloadModalVisible] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [acceptBookingAccess, setAcceptBookingAccess] = useState(false);

    const updateFilters = (event) => {
        let newValue = event.target.value;
        if (event.target.name === "any_tags") {
            newValue = event.target.checked;
        }
        setFilters({...filters, [event.target.name]: newValue});
    }

    const updateFiltersMultiselect = (selectedOptions, actionMeta) => {
        setFilters({...filters, [actionMeta.name]: selectedOptions});
    }

    useEffect(() => {
        if (bookings) {
            setDisplayedBookings(applyFilters(bookings, filters, acceptBookingAccess));
        }
    }, [bookings, filters]);

    useEffect(() => {
        dispatch(calenderData({page: 1, venueId: false}));
        dispatch(getProviderVenueListing());
        dispatch(getEventTags());
    }, []);

    useEffect(() => {
        setALlEventTags(eventTags);
    }, [eventTags]);

    useEffect(() => {
        setVenues(venueListings);
    }, [venueListings]);

    useEffect(() => {
        if (userDetail?.role) {
            let businessId;
            if (userDetail?.role === users.ROLE_VENUE_OWNER) {
                businessId = userDetail?.id;
            } else if (userDetail.role === users.ROLE_VENUE_MANAGER) {
                businessId = userDetail?.bussnessId;
            }
            dispatch(getEventTypes({
                businessId
            }));
        }
    }, [userDetail?.id]);

    useEffect(() => {
        if (userDetail?.role === users.ROLE_VENUE_OWNER) {
            setAcceptBookingAccess(true);
        } else {
            if (userDetail?.manager?.acceptBookingAccess) {
                setAcceptBookingAccess(true);
            }
        }

    }, [userDetail])

    useEffect(() => {
        setEventTypes(businessEventTypes);
    }, [businessEventTypes]);

    useEffect(() => {
        let count = 0;
        filterCategories.map(filter => {
            if (filter.type === 'exact') {
                if (filters[filter.category]) {
                    count += 1;
                }
            } else if (filter.type === 'multiple') {
                if (filters[filter.category]?.length > 0) {
                    count += 1;
                }
            } else if (filter.type === 'range') {
                const [minCategory, maxCategory] = filter.category;
                if (filters[minCategory] || filters[maxCategory]) {
                    count += 1;
                }
            }
        })
        setFilterCount(count);
    }, [filters]);

    return (
        <div class="p-4 w-100">
            <CCard>
                <CCardBody className="p-4">
                    <h2>Reservations</h2>
                    <hr/>
                    <div class="flex-row justify-content-between align-items-start">
                        <Filters
                            filters={filters}
                            setFilters={setFilters}
                            filterCount={filterCount}
                            updateFilters={updateFilters}
                            filterCategories={filterCategories}
                            setFilterModalVisible={setFilterModalVisible}
                        />
                        <div>
                            <CButton style={{width: "180px"}} color="primary" onClick={() => setDownloadModalVisible(true)}>
                                <CIcon icon={cilCloudDownload} className="white"/>&nbsp;
                                Generate Report
                            </CButton>
                        </div>
                    </div>
                    <DataTable
                        columns={columns}
                        data={displayedBookings}
                        selectableRows
                        onSelectedRowsChange={(selected) => setSelectedRows(selected.selectedRows)}
                        pagination
                        />
                </CCardBody>
            </CCard>
            <FilterModal
                visible={filterModalVisible}
                onClose={() => setFilterModalVisible(false)}
                updateFilters={updateFilters}
                updateFiltersMultiselect={updateFiltersMultiselect}
                filters={filters}
                venues={venues}
                eventTypes={eventTypes}
                eventTags={allEventTags}
                acceptBookingAccess={acceptBookingAccess}
                />
            <DownloadModal
                visible={downloadModalVisible}
                onClose={() => setDownloadModalVisible(false)}
                selectedRows={selectedRows}
                />
        </div>
    );
}

export default ReservationTable;

const intialState = {
    openSignInModal: false,
    openSignUpModal: false,
    openForgotModal: false,
}

const HandleModals = (state = intialState , action) => { 
    const {type , data} = action
    switch(type){
        case "OPEN_SIGNUP_MODAL":
            return {
                ...state,
                openSignInModal: false,
                openSignUpModal: true,
                openForgotModal: false
            } 
        case "OPEN_SIGNIN_MODAL" : {
            return {
                ...state,
                openSignInModal: true,
                openSignUpModal: false,
                openForgotModal: false
            } 
        }
        case "OPEN_FORGOT_MODAL" : {
            return {
                ...state,
                openSignInModal: false,
                openSignUpModal: false,
                openForgotModal: true
            } 
        }
        
        case "CLOSE_MODAL" : {
            return {
                ...state,
                openSignInModal: false,
                openSignUpModal: false,
                openForgotModal: false
            } 
        }
       
        default:
            return state;
    }
}

export default HandleModals
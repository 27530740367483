import {LogLevel, PublicClientApplication} from "@azure/msal-browser";
import {logInUser, signUpUser} from "../../redux/action/action";
import {users} from "constants";

const msalConfig = {
    auth: {
        clientId: process.env.REACT_APP_MICROSOFT_AUTHENTICATION_CLIENT_ID,
        authority: "https://login.microsoftonline.com/common",
    },
    cache: {
        cacheLocation: "sessionStorage", // This configures where your cache will be stored
        storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
    },
    system: {
        loggerOptions: {
            logLevel: LogLevel.Trace,
            loggerCallback: (level, message, containsPii) => {
                if (containsPii) {
                    return;
                }
                switch (level) {
                    case LogLevel.Error:
                        console.error(message);
                        return;
                    case LogLevel.Info:
                        console.info(message);
                        return;
                    case LogLevel.Verbose:
                        console.debug(message);
                        return;
                    case LogLevel.Warning:
                        console.warn(message);
                        return;
                    default:
                        console.log(message);
                        return;
                }
            },
        },
    },
};

// Create the main myMSALObj instance
// configuration parameters are located at authConfig.js
const myMSALObj = new PublicClientApplication(msalConfig);
myMSALObj.initialize().then(() => {});

const loginRequest = {
    scopes: ["User.Read"],
    prompt: 'select_account',
};

export const microsoftSignUp = async (dispatch) => {
    return myMSALObj
        .loginPopup(loginRequest)
        .then(response => {
            dispatch(signUpUser({
                email: response.account.username,
                name: response.account.name,
                social_type: users.SOCIAL_TYPE_MICROSOFT,
                ms_access_token: response.accessToken,
            }));
        })
        .catch(function (error) {
            console.log(error);
        });
}

export const microsoftSignIn = async (dispatch) => {
    return myMSALObj
        .loginPopup(loginRequest)
        .then(response => {
            dispatch(logInUser({
                email: response.account.username,
                role: users.ROLE_CUSTOMER,
                social_type: users.SOCIAL_TYPE_MICROSOFT,
            }));
        })
        .catch(function (error) {
            console.log(error);
        });
}

import {CAvatar, CButton, CCard, CCardBody, CFormInput, CFormLabel} from "@coreui/react";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {sendVerificationEmail, updateProfile} from "../../redux/action/action";
import {getProfilePictureUri} from "../../Utils/UriUtils";

const AccountSettings = () => {
    document.title = "Account Settings | DoubleSpot Business";
    document.querySelector('meta[name="description"]').setAttribute(
        "content",
        "Manage your DoubleSpot business account details such as name, e-mail, phone number, and profile picture."
    );
    const dispatch = useDispatch();
    const userDetail = useSelector(state => state.Apis.userDetail);
    const [userData, setUserData] = useState({});
    const [formData, setFormData] = useState({});
    const [profileImage, setProfileImage] = useState('');

    const updateForm = (event) => {
        setFormData({...formData, [event.target.name]: event.target.value});
    };

    const updateImage = (event) => {
        const file = event.target.files && event.target.files[0];
        if (file) {
            setFormData({...formData, images: file});
            const fileReader = new FileReader();
            fileReader.onload = () => setProfileImage(fileReader.result);
            fileReader.readAsDataURL(file);
        }
    }

    const submitForm = () => {
        dispatch(updateProfile(formData));
    };

    useEffect(() => {
        setUserData(userDetail);
        setFormData({
            email: userDetail?.email,
            name: userDetail?.name,
            phone: userDetail?.phone,
            image: userDetail?.profile_image,
        });
        setProfileImage(getProfilePictureUri(userDetail?.profile_image, "../../../assets"));
    }, [userDetail]);

    return (
        <div class="p-4 w-100">
            <CCard>
                <CCardBody className="p-4">
                    <h2>Account Settings</h2>
                    <hr/>
                    <div class="w-50">
                        <div class="pb-2">
                            <CFormLabel htmlFor="name">
                                <div style={{fontWeight: 500}}>Name</div>
                            </CFormLabel>
                            <CFormInput type="text" name="name" placeholder="DoubleSpot Center"
                                        defaultValue={userData?.name} onChange={updateForm}/>
                        </div>
                        <div class="py-2">
                            <CFormLabel htmlFor="email">
                                <div style={{fontWeight: 500}}>E-mail</div>
                            </CFormLabel>
                            <CFormInput type="email" name="email" placeholder="example@doublespot.com"
                                        defaultValue={userData?.email} onChange={updateForm}/>
                            {
                                userData.verify_account ?
                                    null :
                                    <>
                                        <b>Your email is not verified.</b>
                                        &nbsp;
                                        <u style={{cursor: 'pointer'}} onClick={() => dispatch(sendVerificationEmail(userDetail))}>Resend link</u>
                                    </>
                            }
                        </div>
                        <div class="py-2">
                            <CFormLabel htmlFor="phone">
                                <div style={{fontWeight: 500}}>Phone</div>
                            </CFormLabel>
                            <CFormInput type="tel" name="phone" placeholder="1234567890"
                                        defaultValue={userData?.phone} onChange={updateForm}/>
                        </div>
                        <div class="flex-row py-2">
                            <div class="col-8">
                                <CFormLabel htmlFor="image">
                                    <div style={{fontWeight: 500}}>Profile Picture</div>
                                </CFormLabel>
                                <CFormInput type="file" name="image" accept="image/*"
                                            defaultValue={userData?.phone} onChange={updateImage}/>
                            </div>
                            <div class="px-3">
                                <CAvatar src={profileImage} size="xl" shape="rounded-circle" style={{
                                    borderStyle: "solid",
                                    borderColor: "#ff9e8d",
                                    borderWidth: "medium",
                                }}/>
                            </div>
                        </div>

                        <div class="py-2">
                            <CCard>
                                <CCardBody className="p-3" style={{backgroundColor: "#f9f9f9"}}>
                                    <h6>Password (leave blank to keep current password)</h6>
                                    <div class="py-2">
                                        <CFormLabel htmlFor="current_password">Current Password</CFormLabel>
                                        <CFormInput type="password" name="current_password" onChange={updateForm}/>
                                    </div>
                                    <div class="py-2">
                                        <CFormLabel htmlFor="new_password">New Password</CFormLabel>
                                        <CFormInput type="password" name="new_password" onChange={updateForm}/>
                                    </div>
                                    <div class="py-2">
                                        <CFormLabel htmlFor="confirm_new_password">Confirm New Password</CFormLabel>
                                        <CFormInput type="password" name="confirm_new_password" onChange={updateForm}/>
                                    </div>
                                </CCardBody>
                            </CCard>
                        </div>

                        <div class="flex-row pt-3">
                            <div style={{width: "70px"}}>
                                <CButton type="submit" style={{backgroundColor: "#ff7e67", color: "white"}}
                                         onClick={submitForm}>Save</CButton>
                            </div>
                        </div>
                    </div>
                </CCardBody>
            </CCard>
        </div>
    );
};

export default AccountSettings;

import React from "react";
import { Link } from "react-router-dom";
import '../../App.css'

function VerificationSuccess() {
    document.title = "Verification Success | DoubleSpot";
    document.querySelector('meta[name="description"]').setAttribute(
        "content",
        "Congratulations on successfully verifying your email address for your DoubleSpot account!"
    );

    return (<>
        <section class="page_404">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-sm-10 ">
                        <div class="success_background">
                            <h1 class="text-center">Email Verification Success!</h1>
                        </div>
                        <div class="content_box_404 text-center">
                            <p className="">Hooray! Now, it is time to create memories with DoubleSpot!</p>
                            <Link to={'/'}> <a  class="link_404">Go to Home</a></Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </>)
}


export default VerificationSuccess
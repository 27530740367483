import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import ReactStars from "react-rating-stars-component";
import {
    SITE_URL,
    addToWishList,
    setAlert,
} from "../../../redux/action/action";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Navigation, Pagination } from "swiper";

const RecommendedVenues = ({ showHeading, dispatch, venues, setVenues, removeVenue }) => {
    const [showMore, setShowMore] = useState(false);

    const getSingleVenueDetail = (id) => {
        localStorage.setItem("venueId", id)
    }

    function saveInWishList(id, like) {
        const getToken = localStorage.getItem("token");
        const role = localStorage.getItem("userRole");

        if (!getToken || role !== "1") {
            return dispatch(setAlert("Please login first.", "error"))
        }

        dispatch(addToWishList({ "venueId": id }))

        const index = venues.findIndex(val => val.id === id)
        if (like) {
            venues[index] = { ...venues[index], wishlistsStatus: 0 };
            if (removeVenue)
                venues.splice(index, 1);
            setVenues([...venues]);
            dispatch(setAlert('Removed from wishlist', 'success'))
        } else {
            venues[index] = { ...venues[index], wishlistsStatus: 1 };
            setVenues([...venues]);
            dispatch(setAlert('Saved to wishlist', 'success'))
        }
    }

    function showAll() {
        setShowMore(true);
        const btn = document.getElementById('more_btn');
        btn.style.display = 'none';
    }

    const venueCards = (venues) => {
        return venues.map((venue, key) => (
            <>
                <div className='col-lg-3 col-md-4' key={key} onClick={() => getSingleVenueDetail(venue.id)}>
                    <div className='card border-0' >
                        <div className='card_img'>
                            <Link to={'/venue/' + venue.id} target="_blank">
                                <Swiper
                                    pagination={{
                                        dynamicBullets: true,
                                    }}
                                    navigation={true}
                                    modules={[Navigation, Pagination]}
                                    className="mySwiper"
                                >
                                    {
                                        venue?.images.length > 0 &&
                                        venue?.images?.split(",").map((image, minKey) => {
                                            // console.log("check data" , image)
                                            return <>
                                                <div key={minKey}
                                                     className={"carousel-item active"}>
                                                    <SwiperSlide><img className='w-100 d-block'
                                                                      src={SITE_URL + 'images/' + image}/></SwiperSlide>
                                                </div>
                                            </>
                                        })
                                    }

                                </Swiper>
                            </Link>
                            <button onClick={() => {
                                venue?.wishlistsStatus > 0 ? saveInWishList(venue.id, 1) : saveInWishList(venue.id, 0)
                            }} class="like_btn"><i
                                className={venue?.wishlistsStatus > 0 ? "fa-solid fa-heart text-danger" : "fa-solid fa-heart text-white"}></i>
                            </button>
                            {venue?.process_payment ?
                                <div class="price-box">
                                    {`From $${
                                        venue?.min_hours && venue?.max_hours && venue?.min_hours === venue?.max_hours ?
                                            venue?.min_hours * venue?.hourly_price :
                                            `${venue?.hourly_price}/hr`
                                    }`}
                                </div> :
                                null
                            }
                            
                        </div>
                        <div class="card_body">
                            <div class="star_name">
                                <h3 className="title-trunc">
                                    <a style={{color: "inherit"}} href={`/venue/${venue.id}`} target="_blank">{venue.name}</a>
                                </h3>
                            </div>
                            <h6 className="mb-1">
                                <ReactStars
                                    count={5}
                                    size={24}
                                    value={venue.average_rating}
                                    isHalf={true}
                                    edit={false}
                                    emptyIcon={<i className="far fa-star"></i>}
                                    halfIcon={<i className="fa fa-star-half-alt"></i>}
                                    filledIcon={<i className="fa fa-star"></i>}
                                    activeColor="#ffd700"
                                />
                                <sm className="mt-0">({venue.review_count})</sm>
                            </h6>
                            {/* <span>From ${val.hourly_price}/hr</span> */}
                        </div>
                    </div>
                </div>
            </>
        ))
    }

    return (
        <>
            <section className="venues pt-0 pb-5">
                <div className="container">
                    {showHeading ? <h2 className="section_heading">
                        Recommended Venues
                    </h2> : <div className="py-2"/>}
                    <div className="row gy-3">
                        {venueCards(venues?.slice(0, 8))}
                        {/* load all */}
                        {
                            venues?.length > 8 ? (
                                <>
                                    {showMore && venueCards(venues?.slice(8))}

                                    <div className="col-12 ">
                                        <a className="more_btn" id = "more_btn" onClick={() => showAll()}>Load More</a>
                                    </div>
                                </>
                            ) : null
                        }
                    </div>
                </div>
            </section>
        </>
    )
}


export default RecommendedVenues;
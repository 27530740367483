import {
    CFormSelect,
} from "@coreui/react";
import {timeRange, dateToTimeString, getTimeString} from "../../Utils/DateTimeUtils";
import "react-calendar/dist/Calendar.css";

/**
 *
 * @param name {string}
 * @param updateBlockTimeForm {function}
 * @param startTime {string} In format of 00:00 with default value 00:00
 * @param endTime {string} In format 00:00 with default value 23:30
 * @param value {string} In format 00:00:00 with default value 22:00:00
 * @param onChange Function for onChange
 * @param interval {int} Time option interval
 * @returns
 */

const TimePicker = ({
    startTime,
    endTime,
    interval,
    name,
    onChange,
    value,
}) => {
    const dateTimes = timeRange(new Date(`2000-11-30T${startTime}:00`), new Date(`2000-11-30T${endTime}:00`), interval);

    return (
        <CFormSelect required name={name} onChange={onChange} value={value}>
            <option value="">Select Time</option>
            {
                dateTimes.map(time => <option value={dateToTimeString(time)} selected={dateToTimeString(time) === value}>{getTimeString(time, true)}</option>)
            }
        </CFormSelect>
    );
};

TimePicker.defaultProps = {
    startTime: "00:00",
    endTime: "23:30",
    interval: 30
}

export default TimePicker;

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { logInUser } from "../../redux/action/action";
import { useNavigate } from "react-router-dom";
import {CButton, CForm, CFormInput, CFormLabel, CImage} from "@coreui/react";

const Login = () => {document.title = "Login | DoubleSpot Business";
    document.querySelector('meta[name="description"]').setAttribute(
        "content",
        "Login to DoubleSpot to access your event venue provider business account and manage your venues efficiently."
    );
    
    const navigate = useNavigate();
    const dispatch = useDispatch()
    const [formData , setFormData ] = useState({
        role : 2
    })
    const { name, role, verify_account } = useSelector(state => state.Login.userDetail);
    const handleChange = (e) => setFormData({...formData , [e.target.name] : e.target.value})

    const submitForm = (e) => {
        e.preventDefault()
        dispatch(logInUser(formData))
    }

    useEffect(() =>{
        const userRole = localStorage.getItem("userRole");
        const isAuthenticated = userRole === "2" || userRole === "3";
        if (isAuthenticated) {
            navigate("/business/reservations/calendar");
        }
    },[role, verify_account, name])

    return (
        <div className="content d-flex flex-column justify-content-center align-items-center vh-100">
            <div>
                <div className="login-page-logo-container py-4">
                    <CImage className="w-100" src={'../../../assets/images/logo.png'}/>
                </div>
                <div className="text-center">
                    <h2>Business Login</h2>
                </div>
                <CForm onSubmit={submitForm}>
                    <div className="py-2">
                        <CFormLabel htmlFor="email">Email address</CFormLabel>
                        <CFormInput type="email" name="email" required onChange={handleChange} placeholder="example@doublespot.com"/>
                    </div>
                    <div className="py-2">
                        <CFormLabel htmlFor="password">Password</CFormLabel>
                        <CFormInput type="password" name="password" required onChange={handleChange} placeholder="password"/>
                    </div>
                    <p>
                        <a href="/business/forgot-password">
                            <u className="black">Forgot Password?</u>
                        </a>
                    </p>
                    <div className="text-center">
                        <CButton color="primary" size="lg" type="submit">Login</CButton>
                    </div>
                </CForm>
                <div className="text-center py-5">
                    Want to list your space on DoubleSpot?&nbsp;
                    <a
                        href="https://docs.google.com/forms/d/e/1FAIpQLSdHuY_l6by0R6unWNWH2m3NwD9h9KrmLka1kVpuEX0sLnwGbQ/viewform">
                        <u>Join Us</u>
                    </a>
                </div>
            </div>
        </div>
    );
}

export default Login
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import {useEffect, useRef, useState} from "react";
import {
    CButton,
    CButtonGroup,
    CDropdown,
    CDropdownItem,
    CDropdownMenu,
    CDropdownToggle,
    CFormInput,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import {cilCalendar, cilCaretLeft, cilCaretRight, cilPlus} from "@coreui/icons";
import {useDispatch, useSelector} from "react-redux";
import {
    calenderData,
    getEventTags,
    getEventTypes,
    getProviderVenueListing,
} from "../../../../redux/action/action";
import {dateToIsoString, dateToTimeString, weekDays} from "../../../../Utils/DateTimeUtils";
import "react-calendar/dist/Calendar.css";
import BookingModal from "./BookingModal";
import BlockTimeModal from "./BlockTimeModal";
import EditEventModal from "./EditEventModal";
import {bookingsToCalendarEvents} from "./FilterUtils";
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid'
import scrollGridPlugin from '@fullcalendar/scrollgrid';
import {users} from "constants";

const ReservationCalendar = () => {
    document.title = "Reservation Calendar | DoubleSpot Business";
    document.querySelector('meta[name="description"]').setAttribute(
        "content",
        "View and modify your event venue reservation with DoubleSpot's business dashboard."
    );

    const blockTimeFormDefaultData = {repeat: false};
    const editEventFormDefaultData = {};
    const dispatch = useDispatch();
    const calendarRef = useRef(null);
    const currentBookings = useSelector(state => state.Apis.currentBooking);
    const venueListings = useSelector(state => state.Apis.providerVenuesListing);

    const userDetail = useSelector(state => state.Login.userDetail);
    const confirmedEvent = useSelector(state => state.Apis.confirmedEvent);
    const declinedEvent = useSelector(state => state.Apis.declinedEvent)
    const deletedEvent = useSelector(state => state.Apis.deletedEvent);
    const blockedEvent = useSelector(state => state.Apis.bookingDetail);
    const updatedEvent = useSelector(state => state.Apis.updatedEvent);
    const updateEventSuccess = useSelector(state => state.Apis.updateEventSuccess);
    const businessEventTypes = useSelector(state => state.Apis.eventTypes);
    const eventTags = useSelector(state => state.Apis.eventTags);

    const [userData, setUserData] = useState({});
    const [calendarDate, setCalendarDate] = useState('');
    const [calendarTitle, setCalendarTitle] = useState('');
    const [calendarViewTitle, setCalendarViewTitle] = useState("Month");
    const [calendarEvents, setCalendarEvents] = useState([]);
    const [eventTypes, setEventTypes] = useState([]);
    const [venueResources, setVenueResources] = useState([]);
    const [venues, setVenues] = useState([]);
    const [venueFilter, setVenueFilter] = useState({});
    const [eventTypeFilter, setEventTypeFilter] = useState({});
    const [bookings, setBookings] = useState([]);
    const [modalBooking, setModalBooking] = useState({});
    const [bookingModalVisible, setBookingModalVisible] = useState(false);
    const [blockTimeModalVisible, setBlockTimeModalVisible] = useState(false);
    const [blockTimeFormData, setBlockTimeFormData] = useState(blockTimeFormDefaultData);
    const [editEventModalVisible, setEditEventModalVisible] = useState(false);
    const [editEventFormData, setEditEventFormData] = useState({});
    const [allEventTags, setAllEventTags] = useState([]);

    const hasAccess = (accessName) => {
        return userData.role === users.ROLE_VENUE_OWNER || (userData.manager && userData.manager[accessName]);
    }

    const handleVenueCheckboxChange = (event, value) => {
        setVenueFilter({...venueFilter, [value]: event.target.checked});
    };

    const handleEventTypeCheckboxChange = (event, value) => {
        setEventTypeFilter({...eventTypeFilter, [value]: event.target.checked});
    };

    const handleEventClick = (clickInfo) => {
        const booking = bookings.find(booking => booking.id === clickInfo.event.extendedProps.modalId);
        setModalBooking(booking);
        setEditEventFormData({
            start_time: booking.start_time,
            end_time: booking.end_time,
            tags: booking.tags ? booking.tags.map(tag => ({
                label: tag.name,
                value: tag.id,
            })) : [],
        });
        setBookingModalVisible(true);
    }

    const prevButtonClick = () => {
        calendarRef.current.getApi().prev();
    }

    const nextButtonClick = () => {
        calendarRef.current.getApi().next();
    }

    const dateChangeClick = (event) => {
        calendarRef.current.getApi().gotoDate(event.target.value);
    }

    const handleCalendarViewChange = (view) => {
        const currentDate = calendarRef.current.getApi().getDate();
        const isOnCurrentMonth = currentDate.getMonth() === (new Date()).getMonth() &&
            currentDate.getFullYear() === (new Date()).getFullYear();
        calendarRef.current.getApi().changeView(view);
        switch (view) {
            case "dayGridMonth":
                setCalendarViewTitle("Month");
                break;
            case "resourceTimeGridDay":
                setCalendarViewTitle("Day");
                if (isOnCurrentMonth) {
                    calendarRef.current.getApi().today();
                }
                break;
        }
    }

    const handleToDate = (date) => {
        calendarRef.current.getApi().changeView("resourceTimeGridDay");
        setCalendarViewTitle("Day");
        calendarRef.current.getApi().gotoDate(date);
    }

    const handleDateSelect = (selectInfo) => {
        setBlockTimeFormData({
            ...blockTimeFormDefaultData,
            date: selectInfo.startStr.split('T')[0],
            end_date: selectInfo.startStr.split('T')[0],
            start_time: calendarViewTitle === "Day" ? dateToTimeString(selectInfo.start) : "",
            end_time: calendarViewTitle === "Day" ? dateToTimeString(selectInfo.end) : "",
            venueId: calendarViewTitle === "Day" ? selectInfo.resource.id : null,
        });
        setBlockTimeModalVisible(true);
    }

    useEffect(() => {
        dispatch(getEventTags());
        setBlockTimeModalVisible(false);
    }, [blockedEvent]);

    useEffect(() => {
        if (!blockTimeModalVisible) {
            setBlockTimeFormData(blockTimeFormDefaultData);
        }
    }, [blockTimeModalVisible]);

    useEffect(() => {
        if (!editEventModalVisible) {
            setEditEventFormData(editEventFormDefaultData);
        }
    }, [blockTimeModalVisible]);

    useEffect(() => {
        dispatch(getProviderVenueListing());
        dispatch(getEventTags());
    }, []);

    useEffect(() => {
        setAllEventTags(eventTags);
    }, [eventTags]);

    useEffect(() => {
        if (userDetail?.role) {
            let businessId;
            if (userDetail?.role === users.ROLE_VENUE_OWNER) {
                businessId = userDetail?.id;
            } else if (userDetail.role === users.ROLE_VENUE_MANAGER) {
                businessId = userDetail?.bussnessId;
            }
            dispatch(getEventTypes({
                businessId
            }));
        }
    }, [userDetail?.id]);

    useEffect(() => {
        setUserData(userDetail);
    }, [userDetail]);

    useEffect(() => {
        setEventTypes([
            ...businessEventTypes,
            {
                id: 0,
                name: "Other",
                color: 0,
            }
        ]);
        const defaultEventTypeFilter = {};
        businessEventTypes.map(eventType => {
            defaultEventTypeFilter[eventType.id] = true;
        });
        // 0 is reserved for other
        defaultEventTypeFilter[0] = true;
        setEventTypeFilter(defaultEventTypeFilter);
    }, [businessEventTypes]);

    useEffect(() => {
        dispatch(calenderData({page: 1, venueId: false}));
    }, [confirmedEvent, declinedEvent, deletedEvent, blockedEvent, updatedEvent]);

    useEffect(() => {
        if (updateEventSuccess) {
            setModalBooking({
                ...modalBooking,
                ...updatedEvent,
            });
            dispatch(getEventTags());
            setEditEventModalVisible(false);
            setBookingModalVisible(true);
            const clearedEventFormData = {};
            if (updatedEvent.start_time) {
                clearedEventFormData.start_time = updatedEvent.start_time;
            }
            if (updatedEvent.end_time) {
                clearedEventFormData.end_time = updatedEvent.end_time;
            }
            setEditEventFormData({
                start_time: editEventFormData.start_time,
                end_time: editEventFormData.end_time,
                ...clearedEventFormData,
            });
        }
    }, [updatedEvent]);

    useEffect(() => {
        if (currentBookings) {
            setBookings(currentBookings);
            setCalendarEvents(bookingsToCalendarEvents(currentBookings, eventTypeFilter, venueFilter, hasAccess("acceptBookingAccess")));
        }
    }, [currentBookings, venues, venueFilter, eventTypeFilter]);

    useEffect(() => {
        setVenues(venueListings);
        setVenueResources(venueListings.map(venue => ({
            id: venue.id,
            title: venue.name,
        })));
        const defaultFilter = {};
        venueListings.map(listing => {
            defaultFilter[listing.id] = true;
        })
        setVenueFilter(defaultFilter);
    }, [venueListings]);

    useEffect(() => {
        if (calendarRef.current) {
            const calendarApi = calendarRef.current.getApi();
            const onDatesSet = () => {
                setCalendarTitle(calendarApi.getCurrentData().viewTitle);
                setCalendarDate(dateToIsoString(calendarApi.getDate()));
            };

            // Initial setting
            onDatesSet();

            // Add listener for when dates change
            calendarApi.on('datesSet', onDatesSet);

            // Clean up the listener on component unmount
            return () => {
                calendarApi.off('datesSet', onDatesSet);
            };
        }
    }, []);

    return (
        <div className="d-flex flex-column w-100 p-3" style={{maxHeight: "100vh", backgroundColor: "white"}}>
            <div className="flex-row align-items-center justify-content-between pt-1 pb-3">
                <div className="flex-row">
                    <div>
                        <CButtonGroup role="group" aria-label="Basic outlined example">
                            <CButton color="dark" variant="outline" onClick={prevButtonClick}><CIcon icon={cilCaretLeft}/></CButton>
                            <CButton color="dark" variant="outline" onClick={nextButtonClick}><CIcon icon={cilCaretRight}/></CButton>
                        </CButtonGroup>
                    </div>
                    <div className="px-3">
                        <h3 className="mb-0">{calendarTitle}</h3>
                    </div>
                    {
                        calendarViewTitle === "Day" ?
                            // The following code are derived from https://dev.to/codeclown/styling-a-native-date-input-into-a-custom-no-library-datepicker-2in
                            <div>
                                <CButton color="light" href="#"><CIcon icon={cilCalendar}/></CButton>
                                <CFormInput type="date" name="calendar_date" className="datepicker-input" value={calendarDate} onChange={dateChangeClick}/>
                            </div>
                            : null
                    }
                </div>
                <div className="flex-row">
                    <CDropdown className="px-2">
                        <CDropdownToggle color="light">{calendarViewTitle}</CDropdownToggle>
                        <CDropdownMenu>
                            <CDropdownItem href="#"
                                           onClick={() => handleCalendarViewChange("resourceTimeGridDay")}>Day</CDropdownItem>
                            <CDropdownItem href="#"
                                           onClick={() => handleCalendarViewChange("dayGridMonth")}>Month</CDropdownItem>
                        </CDropdownMenu>
                    </CDropdown>
                    {hasAccess("editEventAccess") ?
                        <div className="pe-2">
                            <CButton color="light" onClick={() => setBlockTimeModalVisible(true)}>
                                <CIcon icon={cilPlus} className="primary-color"/> &nbsp; Add Reservation
                            </CButton>
                        </div> : 
                        null
                    }
                    <CDropdown autoClose="outside">
                        <CDropdownToggle color="light">Filters</CDropdownToggle>
                        <CDropdownMenu>
                            <div className="px-3 py-2" style={{width: "500px"}}>
                                <h6>Event Types</h6>
                                {
                                    eventTypes.map((eventType) => {
                                        return (
                                            <div className="form-check">
                                                <input type="checkbox" checked={eventTypeFilter[eventType.id]}
                                                       className={`form-check-input calendar-event-color-${eventType.color ? eventType.color : "gray"}`}
                                                       id={eventType.id}
                                                       onChange={(event) => handleEventTypeCheckboxChange(event, eventType.id)}/>
                                                <label className="check-form-label"
                                                       htmlFor={eventType.id}>{eventType.name}</label>
                                            </div>
                                        )
                                    })
                                }
                                <h6 className="pt-2">Venues</h6>
                                {
                                    venues.map((listing) => {
                                        return (
                                            <div className="form-check">
                                                <input type="checkbox" checked={venueFilter[listing.id]}
                                                       className="form-check-input primary-color"
                                                       id={listing.id}
                                                       onChange={(event) => handleVenueCheckboxChange(event, listing.id)}/>
                                                <label className="check-form-label"
                                                       htmlFor={listing.id}>{listing.name}</label>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </CDropdownMenu>
                    </CDropdown>
                </div>
            </div>
            {calendarViewTitle === "Month" ?
                <div className="flex-row py-1">
                    {
                        weekDays.map(weekday => <div class="col-sm text-center" style={{fontWeight: 500}}>{weekday}</div>)
                    }
                </div> :
                null
            }
            <div className="flex-grow-1">
                <FullCalendar
                    ref={calendarRef}
                    plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin, resourceTimeGridPlugin, scrollGridPlugin]}
                    schedulerLicenseKey={process.env.REACT_APP_FULL_CALENDAR_LICENSE_KEY}
                    headerToolbar={false}
                    initialView='dayGridMonth'
                    dayMinWidth= {calendarViewTitle === "Day" ? 240 : 0}
                    editable={true}
                    selectable={hasAccess("editEventAccess")}
                    selectMirror={true}
                    dayMaxEvents={true}
                    weekends={true}
                    fixedWeekCount={false}
                    dayHeaders={calendarViewTitle === "Day"}
                    events={calendarEvents}
                    resources={calendarViewTitle === "Day" ? venueResources : null}
                    eventClick={handleEventClick}
                    select={handleDateSelect}
                    nowIndicator={true}
                    navLinks={true}
                    navLinkDayClick={handleToDate}
                    eventStartEditable={false}
                    eventDurationEditable={false}
                    eventResourceEditable={false}
                />
            </div>
            <BookingModal
                visible={bookingModalVisible}
                onClose={() => setBookingModalVisible(false)}
                modalBooking={modalBooking}
                dispatch={dispatch}
                setBookingModalVisible={setBookingModalVisible}
                setEditEventModalVisible={setEditEventModalVisible}
                editEventAccess={hasAccess("editEventAccess")}
                acceptBookingAccess={hasAccess("acceptBookingAccess")}
            />
            {hasAccess("editEventAccess")?
                <>
                    <BlockTimeModal
                        visible={blockTimeModalVisible}
                        onClose={() => setBlockTimeModalVisible(false)}
                        blockTimeFormData={blockTimeFormData}
                        setBlockTimeFormData={setBlockTimeFormData}
                        dispatch={dispatch}
                        bookings={bookings}
                        eventTypes={eventTypes}
                        venues={venues}
                        eventTags={allEventTags}
                        setEventTags={setAllEventTags}
                        editEventAccess={hasAccess("editEventAccess")}
                    />
                    <EditEventModal
                        visible={editEventModalVisible}
                        onClose={() => {
                            setEditEventModalVisible(false);
                            setBookingModalVisible(true);
                        }}
                        editEventFormData={editEventFormData}
                        setEditEventFormData={setEditEventFormData}
                        dispatch={dispatch}
                        eventTypes={eventTypes}
                        venues={venues}
                        eventTags={allEventTags}
                        setEventTags={setAllEventTags}
                        modalBooking={modalBooking}
                    />
                </> : null
            }
        </div>
    );
}

export default ReservationCalendar;

import React, {useEffect, useState} from "react";
import {
    CButton,
    CCard,
    CCardBody, 
    CFormInput,
} from "@coreui/react";
import DataTable from "react-data-table-component";
import {useDispatch, useSelector} from "react-redux";
import {
    deleteReservationReport,
    getEventTags,
    getEventTypes, 
    getManagers,
    getProviderVenueListing,
    getReservationReports, 
    setAlert,
} from "../../../../redux/action/action";
import CIcon from "@coreui/icons-react";
import {cilPlus} from "@coreui/icons";
import {applyFilters, columns} from "./FilterUtils";
import EditModal from "./EditModal";
import {downloadColumnOptions} from "../../../../Utils/ReservationReportUtils";
import {bookvenues, users} from "constants";
import {recurrenceRuleToFormData} from "../../../../Utils/DateTimeUtils";
import {useNavigate} from "react-router-dom";

const ScheduledReports = () => {
    document.title = "Scheduled Reports | DoubleSpot Business";
    document.querySelector('meta[name="description"]').setAttribute(
        "content",
        "Organize your scheduled event reports with DoubleSpot's business dashboard."
    );

    const statusOptions = [
        {label: "Confirmed", value: bookvenues.STATUS_CONFIRMED},
        {label: "Pending", value: bookvenues.STATUS_PENDING},
        {label: "Internal", value: bookvenues.STATUS_INTERNAL},
    ];

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const userDetail = useSelector(state => state.Apis.userDetail);
    const reservationReports = useSelector(state => state.Apis.reservationReports);
    const updatedReservationReport = useSelector(state => state.Apis.updatedReservationReport);
    const managerListing = useSelector(state => state.Apis.managers);
    const venuesListing = useSelector(state => state.Apis.providerVenuesListing);
    const businessEventTypes = useSelector(state => state.Apis.eventTypes);
    const eventTags = useSelector(state => state.Apis.eventTags);

    const [managers, setManagers] = useState([]);
    const [venues, setVenues] = useState([]);
    const [eventTypes, setEventTypes] = useState([]);
    const [tags, setTags] = useState([]);
    const [displayedReservationReports, setDisplayedReservationReports] = useState([]);
    const [editModalVisible, setEditModalVisible] = useState(false);
    const [editJobFormData, setEditJobFormData] = useState({});
    const [filters, setFilters] = useState({});

    const updateFilters = (event) => {
        setFilters({...filters, [event.target.name]: event.target.value});
    };

    const handleAddButtonClick = () => {
        setEditJobFormData({
            id: null,
            recipients: [],
            days_ahead: 1,
            columns: downloadColumnOptions,
            sort_by: -1,
            sort_desc: false,
            statuses: [],
            venues: [],
            event_types: [],
            tags: [],
            match_any_tags: false,
            frequency: 'D',
        });
        setEditModalVisible(true);
    };

    const handleEditButtonClick = (reportJob) => {
        setEditJobFormData({
            id: reportJob?.id,
            name: reportJob?.name,
            recipients: reportJob?.email_job?.users.map(user => ({
                label: user.name,
                value: user.id,
            })),
            days_ahead: reportJob?.days_ahead,
            columns: reportJob?.columns?.split(",")?.map(column => downloadColumnOptions[parseInt(column)]),
            sort_by: reportJob?.sort_by,
            sort_desc: reportJob?.sort_desc,
            statuses: reportJob?.statuses?.split(",")?.map(status => statusOptions[parseInt(status) - 1]),
            venues: reportJob?.venues?.map(venue => ({
                label: venue.name,
                value: venue.id,
            })),
            event_types: reportJob?.eventtypes?.map(event_type => ({
                label: event_type.name,
                value: event_type.id,
            })),
            tags: reportJob?.tags?.map(tag => ({
                label: tag.name,
                value: tag.id,
            })),
            match_any_tags: reportJob?.match_any_tags,
            ...recurrenceRuleToFormData(reportJob?.email_job?.job),
        });
        setEditModalVisible(true);
    };

    const handleDeleteButtonClick = (id) => {
        if (window.confirm("Are you sure you want to delete this scheduled reservations report?")) {
            dispatch(deleteReservationReport({id}));
        }
    }

    useEffect(() => {
        setDisplayedReservationReports(applyFilters(reservationReports, filters));
    }, [reservationReports, filters]);

    useEffect(() => {
        dispatch(getReservationReports());
        setEditModalVisible(false);
    }, [updatedReservationReport]);

    useEffect(() => {
        dispatch(getManagers());
        dispatch(getProviderVenueListing());
        dispatch(getEventTags());
    }, []);

    useEffect(() => {
        if (userDetail?.role) {
            if (userDetail.role !== users.ROLE_VENUE_OWNER && !userDetail.manager?.editReservationReportsAccess) {
                dispatch(setAlert('You do not have the permissions to view scheduled reservation reports.','error'));
                navigate("/business/reservations/calendar");
            }

            let businessId;
            if (userDetail?.role === users.ROLE_VENUE_OWNER) {
                businessId = userDetail?.id;
            } else if (userDetail.role === users.ROLE_VENUE_MANAGER) {
                businessId = userDetail?.bussnessId;
            }
            dispatch(getEventTypes({
                businessId
            }));
        }
    }, [userDetail]);

    useEffect(() => {
        setVenues(venuesListing);
    }, [venuesListing]);

    useEffect(() => {
        setEventTypes(businessEventTypes);
    }, [businessEventTypes]);

    useEffect(() => {
        setTags(eventTags);
    }, [eventTags]);

    useEffect(() => {
        setManagers(managerListing);
    }, [managerListing]);

    return (
        <div class="p-4 w-100">
            <CCard>
                <CCardBody className="p-4">
                    <div className="flex-row justify-content-between align-items-end">
                        <div>
                            <h2>Scheduled Reports</h2>
                            <p className="modal-subtitle mb-0">The reports will be sent to the recipients' email
                                addresses on a regular basis.</p>
                        </div>
                        <div>
                            <CButton color="light" onClick={handleAddButtonClick}>
                                <CIcon icon={cilPlus} className="primary-color"/> &nbsp; Add Scheduled Report
                            </CButton>
                        </div>
                    </div>
                    <hr/>
                    <div class="flex-row justify-content-between align-items-start">
                        <div class="flex-row flex-wrap align-items-center">
                            <div class="pe-2 pb-3">
                                <CFormInput name="search" placeholder="Search..." onChange={updateFilters}/>
                            </div>
                        </div>
                    </div>
                    <DataTable
                        columns={columns(handleEditButtonClick, handleDeleteButtonClick)}
                        data={displayedReservationReports}
                        pagination
                    />
                </CCardBody>
            </CCard>
            <EditModal
                visible={editModalVisible}
                onClose={() => setEditModalVisible(false)}
                editJobFormData={editJobFormData}
                setEditJobFormData={setEditJobFormData}
                dispatch={dispatch}
                managers={managers}
                venues={venues}
                eventTypes={eventTypes}
                eventTags={tags}
            />
        </div>
    );
}

export default ScheduledReports;

import {CButton, CForm, CFormLabel, CFormSelect, CModal, CModalBody, CModalHeader, CModalTitle} from "@coreui/react";
import MultiSelectCheckbox from "../../../components/MultiSelectCheckbox";
import {useEffect, useState} from "react";
import {downloadColumnOptions, generatePdf} from "../../../../Utils/ReservationReportUtils";

const DownloadModal = ({visible, onClose, selectedRows}) => {
    const [downloadColumns, setDownloadColumns] = useState(downloadColumnOptions);
    const [sortBy, setSortBy] = useState(-1);
    const [sortDesc, setSortDesc] = useState(false);
    const [displayTotalPrice, setDisplayTotalPrice] = useState(true);
    const [displayBookedPrice, setDisplayBookedPrice] = useState(false);

    const downloadPdf = (event) => {
        event.preventDefault();
        const pdf = generatePdf(
            downloadColumns.map(column => column.value),
            selectedRows,
            sortBy,
            sortDesc,
            displayTotalPrice,
            displayBookedPrice,
        );
        pdf?.save('Report.pdf');
    }

    useEffect(() => {
        if (!downloadColumns.map(column => column.value).includes(sortBy)) {
            setSortBy(-1);
        }
    }, [downloadColumns]);

    return (
        <CModal scrollable backdrop="static" visible={visible} onClose={() => {
            onClose();
            setDownloadColumns(downloadColumnOptions);
        }}>
            <CModalHeader>
                <CModalTitle>Generate Report: {selectedRows.length} rows selected</CModalTitle>
            </CModalHeader>
            <CModalBody className="business-modal-body" style={{height: "500px"}}>
                <CForm onSubmit={downloadPdf}>
                    <div class="pb-3">
                        <CFormLabel>Columns</CFormLabel>
                        <MultiSelectCheckbox
                            value={downloadColumns || []}
                            name="columns"
                            onChange={(selectedOptions) => setDownloadColumns(selectedOptions)}
                            options={downloadColumnOptions}
                        />
                    </div>
                    <div className="pb-3">
                        <CFormLabel>Sort by</CFormLabel>
                        <CFormSelect name="sort_by" onChange={(event) => setSortBy(parseInt(event.target.value))}>
                            {
                                downloadColumns.map(column => <option value={column.value}>{column.label}</option>)
                            }
                            <option value={-1}>Default</option>
                        </CFormSelect>
                    </div>
                    <div className="form-check pb-1">
                        <input type="checkbox" checked={sortDesc}
                               className="form-check-input primary-color"
                               name="sort_desc"
                               onChange={(event) => setSortDesc(event.target.checked)}/>
                        <label className="check-form-label" htmlFor="sort_desc">Sort Descending</label>
                    </div>
                    <div className="form-check pb-1">
                        <input type="checkbox" checked={displayTotalPrice}
                               className="form-check-input primary-color"
                               name="display_total_price"
                               onChange={(event) => setDisplayTotalPrice(event.target.checked)}/>
                        <label className="check-form-label" htmlFor="display_total_price">Display total
                            price</label>
                    </div>
                    <div className="form-check pb-3">
                        <input type="checkbox" checked={displayBookedPrice}
                               className="form-check-input primary-color"
                               name="display_booked_price"
                               onChange={(event) => setDisplayBookedPrice(event.target.checked)}/>
                        <label className="check-form-label" htmlFor="display_booked_price">Display "Revenue" as
                            "Booked Price"</label>
                    </div>
                    <CButton color="primary" type="submit">Download</CButton>
                </CForm>
            </CModalBody>
        </CModal>
    )
};

export default DownloadModal;

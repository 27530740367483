export const openSignUpModal = () => (
    {
        type : "OPEN_SIGNUP_MODAL",
    }
)

export const openSignInModal = () => (
    {
        type : "OPEN_SIGNIN_MODAL",
    }
)

export const openForgotModal = () => (
    {
        type : "OPEN_FORGOT_MODAL",
    }
)

export const closeModal = () => (
    {
        type : "CLOSE_MODAL",
    }
)
import '@chatscope/chat-ui-kit-styles/dist/default/styles.min.css';
import {CButton, CCard, CCardBody} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import {cilArrowLeft} from "@coreui/icons";
import {useDispatch, useSelector} from "react-redux";
import {useEffect, useState} from "react";
import {setAlert} from "../../../../redux/action/action";
import {Avatar, Message, MessageList} from "@chatscope/chat-ui-kit-react";
import {getProfilePictureUri} from "../../../../Utils/UriUtils";
import {useNavigate} from "react-router-dom";
import Markdown from "react-markdown";
import {users} from "constants";

const Conversation = () => {
    const conversation = JSON.parse(localStorage.getItem("conversation"));
    const userName = conversation?.form_submission?.name;
    const messages = conversation?.messages;

    document.title = `DoubleSpot AI Conversation${userName ? ` with ${userName}` : ""} | DoubleSpot Business`;
    document.querySelector('meta[name="description"]').setAttribute(
        "content",
        "View conversations of your DoubleSpot AI chatbot with DoubleSpot's business dashboard."
    );

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const userDetail = useSelector(state => state.Apis.userDetail);

    const [providerProfile, setProviderProfile] = useState({});

    useEffect(() => {
        if (!conversation) {
            dispatch(setAlert('Cannot find conversation.', 'error'));
            navigate("/business/ai/conversations");
        }
    }, []);

    useEffect(() => {
        if (userDetail.role) {
            const business = userDetail.role === users.ROLE_VENUE_OWNER ? userDetail : userDetail.manager?.business;
            const businessPlan = business?.business_plan;
            if (!businessPlan?.chatbotId) {
                dispatch(setAlert('Your DoubleSpot Business plan does not include AI chatbot.', 'error'));
                navigate("/business/reservations/calendar");
            } else if (userDetail.role !== users.ROLE_VENUE_OWNER && !userDetail.manager?.aiAccess) {
                dispatch(setAlert('You do not have access to DoubleSpot AI.', 'error'));
                navigate("/business/reservations/calendar");
            } else {
                setProviderProfile(business);
            }
        }
    }, [userDetail]);

    return (
        <div class="p-4 w-100 vh-100">
            <CCard className="h-100">
                <CCardBody className="p-4 h-100 d-flex flex-column">
                    <h2>{`DoubleSpot AI Conversation${userName ? ` with ${userName}` : ""}`}</h2>
                    <hr/>
                    <div class="flex-row justify-content-between align-items-start">
                        <CButton color="light" href="#" onClick={() => navigate("/business/ai/conversations")}>
                            <CIcon icon={cilArrowLeft} className="primary-color"/> &nbsp; Back to Conversations
                        </CButton>
                    </div>
                    <MessageList className="pt-4" autoScrollToBottomOnMount={false}>
                        {messages?.map((message, index) => {
                            const user = message.role === "user";
                            return (
                                <Message
                                    key={index}
                                    model={{
                                        payload: (
                                            <Message.CustomContent>
                                                <Markdown>{message.content}</Markdown>
                                            </Message.CustomContent>
                                        ),
                                        sender: user ? (userName || "Anonymous Customer") : providerProfile?.name,
                                        direction: user ? "outgoing" : "incoming",
                                        position: "single",
                                    }}
                                >
                                    {
                                        user ? null : <Avatar src={getProfilePictureUri(providerProfile?.profile_image, "../../../assets")}/>
                                    }
                                </Message>
                            )
                        })}
                    </MessageList>
                </CCardBody>
            </CCard>
        </div>
    )
}

export default Conversation;

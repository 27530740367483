import { call, put, takeEvery } from "redux-saga/effects";
import {
  ACCOUNT_VERIFY,
  DEFAULT_URL,
  LOGOUT_SUCCESS,
  VERIFY_FAIL,
  VERIFY_SUCCESS,
  setAlert,
  SUCCESS_LOGOUT,
  USER_LOGIN,
  USER_LOGIN_API_FAIL,
  USER_LOGIN_API_SUCCESS,
} from "../action/action";


function* userLogin(action) {
  try {
    localStorage.setItem("email", action.payload.email)
    localStorage.setItem("userId", action.payload.id)
    const response = yield call(fetch, `${DEFAULT_URL}signIn`, {
      method: 'POST',
      body: JSON.stringify(action.payload),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const data = yield response.json();
    if (!data.success) {
      yield put(setAlert(data.msg, 'error'));
      return;
    }

    yield put({ type: USER_LOGIN_API_SUCCESS, data });
    if (data.body.verify_account) {
      yield put(setAlert('User login successful', 'success'));
    } else {
      yield put(setAlert('Please remember to check your email inbox to verify your email address.', 'success'))
    }

  } catch (error) {
    yield put({ type: USER_LOGIN_API_FAIL, error });
    yield put(setAlert('User login failed', 'error'));
  }
}


function* verifyEmail(action) {
  try {
    const response = yield call(fetch, `${DEFAULT_URL}verifyAccount`, {
      method: 'POST',
      body: JSON.stringify(action.payload),
      headers: {
        'Content-Type': 'application/json'
      }
    });
    const data = yield response.json();
    if (!data.success) {
      yield put(setAlert(data.msg, 'error'));
      return
    }

    yield put({ type: VERIFY_SUCCESS, data });
  } catch (error) {
    yield put({ type: VERIFY_FAIL, error });
  }
}

function* logout(action) {
  yield put({ type: SUCCESS_LOGOUT, payload: {} })
  yield put(setAlert('Logout Successful', 'success'))
}

function* login() {
  yield takeEvery(ACCOUNT_VERIFY, verifyEmail);
  yield takeEvery(USER_LOGIN, userLogin);
  yield takeEvery(LOGOUT_SUCCESS, logout);
}


export default login




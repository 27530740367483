import {
    CAvatar,
    CBadge,
    CContainer,
    CImage,
    CNavGroup,
    CNavItem,
    CNavTitle,
    CSidebar,
    CSidebarBrand,
    CSidebarHeader,
    CSidebarNav,
} from "@coreui/react";
import CIcon from "@coreui/icons-react";
import {
    cilAccountLogout,
    cilCalendarCheck,
    cilChartLine,
    cilEqualizer,
    cilSpeech,
    cibStripe,
    cilUser,
} from "@coreui/icons";
import {getProfilePictureUri} from "../../Utils/UriUtils";
import {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {
    DEFAULT_URL,
    getUserProfile,
    logout,
} from "../../redux/action/action";
import {useNavigate} from "react-router-dom";
import {users} from "constants";

const BusinessSidebar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const userDetail = useSelector(state => state.Apis.userDetail);

    const [userData, setUserData] = useState({});
    const [business, setBusiness] = useState({});

    const performLogout = () => {
        localStorage.clear();
        navigate('/business/login');
        dispatch(logout());
    }

    const getStripeUrl = async () => {
        await fetch(DEFAULT_URL + 'stripeLogin/' + business?.stripeid).then((res) => {
            return(res.json())
        }).then((data) => {
            window.location.href = data.body.url
        })
    }

    const hasAccess = (accessName) => {
        return userData.role === users.ROLE_VENUE_OWNER || (userData.manager && userData.manager[accessName]);
    }

    useEffect(() => {
        dispatch(getUserProfile())
    }, []);

    useEffect(() => {
        setUserData(userDetail);
        setBusiness(userDetail.role === users.ROLE_VENUE_OWNER ? userDetail : userDetail.manager?.business);
    }, [userDetail]);

    return (
        <CSidebar className="border-end" colorScheme="dark" position="fixed">
            <CSidebarHeader className="pb-0">
                <CSidebarBrand>
                    <CImage src={'../../../assets/images/logo.png'} width={'100%'} height={'100%'} className="object-fit-contain px-2 py-1"/>
                </CSidebarBrand>
            </CSidebarHeader>
            <CSidebarHeader className="py-0">
                <CContainer className="flex-column justify-content-center">
                    <CContainer className="flex-row justify-content-center py-3">
                        <CAvatar size="xl" shape="rounded-circle" src={getProfilePictureUri(userData?.profile_image, "../../../assets")}/>
                    </CContainer>
                    <h5 class="text-center">{userData?.name}</h5>
                </CContainer>
            </CSidebarHeader>
            <CSidebarNav className="pt-0 border-bottom">
                <CNavTitle>Business Operations</CNavTitle>
                <CNavGroup toggler={<><CIcon customClassName="nav-icon" icon={cilCalendarCheck} style={{color: "#ff7e67"}}/> Reservations</>}>
                    <CNavItem href="#" onClick={() => navigate("/business/reservations/calendar")} className="py-1"><span className="nav-icon"><span className="nav-icon-bullet"/></span> Calendar View</CNavItem>
                    <CNavItem href="#" onClick={() => navigate("/business/reservations/table")} className="py-1"><span className="nav-icon"><span className="nav-icon-bullet"/></span> Table View</CNavItem>
                    {
                        hasAccess("editReservationReportsAccess") ?
                            <CNavItem href="#" onClick={() => navigate("/business/reservations/scheduled-reports")} className="py-1"><span className="nav-icon"><span className="nav-icon-bullet"/></span> Scheduled Reports</CNavItem>
                            : null
                    }
                </CNavGroup>
                {
                    hasAccess("editVenueAccess") || hasAccess("editTeamAccess") || hasAccess("editEventAccess") || hasAccess("editEmailRestrictionAccess") ?
                        <CNavGroup toggler={<><CIcon customClassName="nav-icon" icon={cilEqualizer} style={{color: "#ff7e67"}}/> Manage</>}>
                            {hasAccess("editVenueAccess") ?
                                <CNavItem href="#" onClick={() => navigate("/business/venues")} className="py-1"><span className="nav-icon"><span className="nav-icon-bullet"/></span> Venues</CNavItem> :
                                null
                            }
                            {hasAccess("editTeamAccess") ?
                                <CNavItem href="#" onClick={() => navigate("/business/team")} className="py-1"><span className="nav-icon"><span className="nav-icon-bullet"/></span> Team</CNavItem> :
                                null
                            }
                            {hasAccess("editEmailRestrictionAccess") ?
                                <CNavItem href="#" onClick={() => navigate("/business/customers")} className="py-1"><span className="nav-icon"><span className="nav-icon-bullet"/></span> Customers</CNavItem> :
                                null
                            }
                            {hasAccess("editEventAccess") ?
                                <CNavItem href="#" onClick={() => navigate("/business/event-types")} className="py-1"><span className="nav-icon"><span className="nav-icon-bullet"/></span> Event Types</CNavItem> :
                                null
                            }
                            {hasAccess("editEventAccess") ?
                                <CNavItem href="#" onClick={() => navigate("/business/event-tags")} className="py-1"><span className="nav-icon"><span className="nav-icon-bullet"/></span> Event Tags</CNavItem> :
                                null
                            }
                        </CNavGroup>
                        : null
                }
                {
                    business?.business_plan?.chatbotId && hasAccess("aiAccess") ? <CNavGroup toggler={<><CIcon customClassName="nav-icon" icon={cilSpeech} style={{color: "#ff7e67"}}/> DoubleSpot AI</>}>
                        <CNavItem href="#" onClick={() => navigate("/business/ai/chatbot-preview")} className="py-1"><span className="nav-icon"><span className="nav-icon-bullet"/></span> Chatbot Preview</CNavItem>
                        <CNavItem href="#" onClick={() => navigate("/business/ai/conversations")} className="py-1"><span className="nav-icon"><span className="nav-icon-bullet"/></span> Conversations</CNavItem>
                    </CNavGroup> : null
                }
                <CNavItem href="#" onClick={() => navigate("/business/analytic-reports")}><CIcon customClassName="nav-icon" icon={cilChartLine} style={{color: "#ff7e67"}}/>
                    Analytic Reports &nbsp; <CBadge className="black" color="light">Demo</CBadge>
                </CNavItem>
                <CNavTitle>Account Actions</CNavTitle>
                <CNavItem href="#" onClick={() => navigate("/business/account")}><CIcon customClassName="nav-icon" icon={cilUser} style={{color: "#ff7e67"}}/> Account Settings</CNavItem>
                {
                    business?.stripeid ? <CNavItem href="#" onClick={getStripeUrl}><CIcon customClassName="nav-icon" icon={cibStripe} style={{color: "#ff7e67"}}/> Stripe Account</CNavItem> : null
                }
                <CNavItem href="#" onClick={performLogout}><CIcon customClassName="nav-icon" icon={cilAccountLogout} style={{color: "#ff7e67"}}/> Logout</CNavItem>
            </CSidebarNav>
        </CSidebar>
    );
};

export default BusinessSidebar;

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import {
    getUserProfile,
    updateProfile,
    sendVerificationEmail
} from '../../redux/action/action';
import { getProfilePictureUri } from '../../Utils/UriUtils';

function Profile() {
    document.title = "Profile | DoubleSpot";
    document.querySelector('meta[name="description"]').setAttribute(
        "content",
        "Easily view and edit your DoubleSpot profile."
    );

    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        image: ''
    })

    const [selectedFile, setSelectedFile] = useState(null);

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        setSelectedFile(file);
        const setSubmitData =  {
            email :  formData?.email,
            name :  formData?.name,
            phone :  formData?.phone
        }
        if(file){
            setSubmitData.images =  file
        }
        // updateProfile
        dispatch(updateProfile(setSubmitData))
    };

    const [user, setUser] = useState({})
    const [phoneError, setPhoneError] = useState('')
    const dispatch = useDispatch()
    const userDetail = useSelector(state => state.Login.userDetail);
    const onChange = (e) => setFormData({ ...formData, [e.target.name]: e.target.value })

    const [oneActive, setOneActive] = useState(true)
    const [twoActive, setTwoActive] = useState(false)
    const handleOne = () => {
        setTwoActive(false)
        setOneActive(true)
      }


    //for check the userDetail 
    useEffect(() => {
        setUser(userDetail)
        setFormData({
            email: userDetail?.email,
            name: userDetail?.name,
            phone: userDetail?.phone,
            image: userDetail?.profile_image
        })
    }, [userDetail])

    useEffect(() => {
        dispatch(getUserProfile())
    }, [])

    function updateUserProfile(e) {
        e.preventDefault()
        var num = `^\d{10}$`
        if (!num.match(formData.phone) && formData.phone.length != 10) {
            setPhoneError("Phone number is incorrect")
            return
        }
        setPhoneError("")

        const setSubmitData =  {
        email :  formData?.email,
        name :  formData?.name,
        phone :  formData?.phone
        }
        if(selectedFile){
            setSubmitData.images =  selectedFile
        }
        // updateProfile
        dispatch(updateProfile(setSubmitData))
    }

    return (
        <>
            <section className="my_account py-5" id="my_acc">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3  col-sm-12">
                            <div className="tab_shadow tab_menu rounded-4 h-100">
                                <div className="user_image w-100 text-center mb-4">
                                    <div className="proUImg mb-4">
                                        <img src={getProfilePictureUri(formData.image, "../../../assets")}
                                            className="rounded-circle"
                                            alt="DoubleSpot.com" />
                                        <label htmlFor='upp' style={{display: "block"}}>
                                            <i class="fa-solid fa-pen-to-square"></i>
                                        </label>
                                        <input type='file' onChange={handleFileChange}  id='upp' className='d-none' />
                                        
                                    </div>
                                    <h2>{userDetail?.name}</h2>
                                    <p>{userDetail?.email}</p>
                                </div>

                                <div className="nav d-md-flex flex-wrap justify-content-lg-start justify-content-center d-block text-start nav-pills "
                                    id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                    <a href="#" className={oneActive ? "nav-link  active"  : "nav-link" }
                                        id="v-pills-profile-tab" data-bs-toggle="pill"
                                        data-bs-target="#v-pills-profile" role="tab" aria-controls="v-pills-profile"
                                        aria-selected="true" onClick={() => handleOne()}> <span className="tab_icon "><i
                                            className="fa-solid fa-user text-white"></i> </span>
                                        Profile</a>
                                    {/* <a href="#" className={twoActive ? "nav-link  active"  : "nav-link" }
                                        id="v-pills-paymathod-tab" data-bs-toggle="pill"
                                        data-bs-target="#v-pills-paymathod" role="tab" aria-controls="v-pills-paymathod"
                                        aria-selected="true" onClick={() => handleTwo()}> <span className="tab_icon "><i className="fa-regular fa-credit-card"></i>
                                        </span>
                                        Payment method</a> */}
                                    {/* <a className="nav-link" id="v-pills-logout-tab" data-bs-toggle="modal" data-bs-target="#logout">
                                        <span className="tab_icon"> <i className="fa-solid fa-power-off"></i>
                                        </span> Logout</a> */}
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-9 col-sm-12 mt-lg-0 mt-4 ">
                            <div className="tab-content tab_shadow rounded-4 h-100" id="v-pills-tabContent">
                                <div 
                                    className={oneActive ? "tab-pane fade show active p-4 profile_tab"  : "tab-pane fade p-4 profile_tab" }
                                    id="v-pills-profile" role="tabpanel"
                                    aria-labelledby="v-pills-profile-tab" tabindex="0">
                                    <div className="row justify-content-between">
                                        
                                        {/* <div className="col-md-6 col-sm-12 d-none">
                                            <h2 className="mb-4">Profile</h2>
                                            <h4>Name</h4>
                                            <p>John Deo</p>
                                            <h4>Email</h4>
                                            <p>ashrafhossain@gmail.com</p>
                                            <h4>Phone Number</h4>
                                            <p>+1 458 4582 5789</p>
                                            <button className="next_btn px-5 fw-semibold">Edit Profile</button>
                                        </div> */}

                                        <div className="col-md-5">
                                            <h2 className="mb-4">Profile</h2>
                                            <form onSubmit={(e) => updateUserProfile(e)}>
                                                <div className="form-group">
                                                    <label for="text">Name</label>
                                                    <input type="text" className="form-control box_shadow "
                                                        onChange={onChange}
                                                        name='name'
                                                        value={formData.name}
                                                        placeholder='username'
                                                    />
                                                </div>
                                                <div className="form-group">
                                                    <label for="text">Email</label>
                                                    <input type="email" className="form-control box_shadow "
                                                        onChange={onChange}
                                                        name='email'
                                                        readOnly={user.social_type}
                                                        value={formData.email}
                                                        placeholder='useremail'
                                                    />
                                                    {
                                                        user.social_type === 1 ?
                                                            <img src="../../../assets/images/google_icon.svg"
                                                                 title="This account is registered using Google."
                                                            /> :
                                                        user.social_type === 2 ?
                                                            <img src="../../../assets/images/microsoft_icon.svg"
                                                                 title="This account is registered using Microsoft."
                                                            /> :
                                                            null
                                                    }
                                                    {
                                                        user.verify_account ?
                                                        null :
                                                        <>
                                                            <b>Your email is not verified.</b>
                                                            &nbsp;
                                                            <u style={{cursor: 'pointer'}} onClick={() => dispatch(sendVerificationEmail(userDetail))}>Resend link</u>
                                                        </>
                                                    }
                                                </div>
                                                <div className="form-group">
                                                    <label for="text">Phone Number</label>
                                                    <input type="number" className="form-control box_shadow "
                                                        onChange={onChange}
                                                        name='phone'
                                                        placeholder='phone'
                                                        value={formData.phone}
                                                    />
                                                    <span className='text-danger'>{phoneError}</span>
                                                </div>
                                                <button type='submit' className="next_btn px-5 rounded">Update</button>
                                            </form>

                                        </div>

                                        <div className="col-md-6 col-sm-12">
                                            <img src="../../../assets/images/user_profile_sideimg01.png" className="w-100 h-100"
                                                alt="DoubleSpot.com" />
                                        </div>
                                    </div>


                                </div>

                                <div className={twoActive ? "tab-pane fade show active p-4 profile_tab"  : "tab-pane fade p-4 profile_tab" } 
                                    id="v-pills-paymathod" role="tabpanel"
                                    aria-labelledby="v-pills-paymathod-tab" tabindex="0">

                                    <div className="row">

                                        <div className="col-md-12">
                                            <div className="hearder">
                                                <h5>Add a New Payment Method <img src="./images/card01.png" className="mx-1"
                                                    alt="DoubleSpot.com" />
                                                    <img src="../../../assets/images/card02.png" className="mx-1" alt="DoubleSpot.com" />
                                                    <img src="../../../assets/images/card03.png" className="mx-1" alt="DoubleSpot.com" />
                                                    <img src="../../../assets/images/card04.png" className="mx-1" alt="DoubleSpot.com" /></h5>
                                                <small>Creyv accepts all major debit and credit cards</small>
                                            </div>
                                            <div className="pay_group  d-flex justify-content-center align-itmes-end gap-2">
                                                <div className="form-group">
                                                    <label for="text" className="mb-0">Card Number</label>
                                                    <input type="text"
                                                        className="form-control border-bottom rounded-0 border-dark text-muted px-0"
                                                        placeholder="1324 1234 1234 1234" />
                                                </div>
                                                <div className="form-group">
                                                    <label for="text" className="mb-0">Expiration Date</label>
                                                    <input type="text"
                                                        className="form-control border-bottom rounded-0 border-dark text-muted px-0 w-75"
                                                        placeholder="MM / YY" />
                                                </div>
                                                <div className="form-group">
                                                    <label for="text" className="mb-0">CVV</label>
                                                    <input type="text"
                                                        className="form-control border-bottom rounded-0 border-dark text-muted px-0 w-50"
                                                        placeholder="CVV" />
                                                </div>
                                                <div className="form-group">
                                                    <button className="next_btn fs-5 border-0 mt-4" type="button">Add Payment
                                                        Card</button>
                                                </div>
                                            </div>

                                            <div className="row saved_cards">
                                                <div className="col-md-6">
                                                    <a href="checkout.html" className="text-decoration-none">
                                                        <ul className="box_shadow rounded-4 py-4 px-2 list-unstyled">
                                                            <li>
                                                                <div className="row align-items-center w-100">
                                                                    <div className="col-6 d-flex align-items-center">
                                                                        <p> John Marker</p>
                                                                    </div>
                                                                    <div
                                                                        className="col-6 d-flex align-items-center justify-content-end">
                                                                        <label className="radio-button m-0">
                                                                            <input type="radio" className="radio-button__input"
                                                                                checked="" id="choice1-1" name="choice1" />
                                                                            <span className="radio-button__control"></span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <p>XXXX-XXXX-XXXX-4567</p>
                                                            </li>
                                                            <li>
                                                                <div className="row  w-100">
                                                                    <div className="col-sm-6 col-12">
                                                                        <p>
                                                                            Expiry: Jan 2023
                                                                        </p>
                                                                    </div>
                                                                    <div
                                                                        className="col-sm-6 col-12 d-flex align-items-center justify-content-end">
                                                                        <small className="edit_btn me-3 ">
                                                                            <img src="../../../assets/images/edit_btn.png"
                                                                                alt="DoubleSpot.com" />
                                                                        </small>
                                                                        <small className="delete_btn">
                                                                            <img src="../../../assets/images/delete_btn.png"
                                                                                alt="DoubleSpot.com" />
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </a>
                                                </div>
                                                <div className="col-md-6">
                                                    <a href="checkout.html" className="text-decoration-none">
                                                        <ul className="box_shadow rounded-4 py-4 px-2 list-unstyled">
                                                            <li>
                                                                <div className="row align-items-center w-100">
                                                                    <div className="col-6 d-flex align-items-center">
                                                                        <p> John Marker</p>
                                                                    </div>
                                                                    <div
                                                                        className="col-6 d-flex align-items-center justify-content-end">
                                                                        <label className="radio-button m-0">
                                                                            <input type="radio" className="radio-button__input"
                                                                                checked="" id="choice1-1" name="choice1" />
                                                                            <span className="radio-button__control"></span>
                                                                        </label>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                            <li>
                                                                <p>XXXX-XXXX-XXXX-4567</p>
                                                            </li>
                                                            <li>
                                                                <div className="row  w-100">
                                                                    <div className="col-sm-6 col-12">
                                                                        <p>
                                                                            Expiry: Jan 2023
                                                                        </p>
                                                                    </div>
                                                                    <div
                                                                        className="col-sm-6 col-12 d-flex align-items-center justify-content-end">
                                                                        <small className="edit_btn me-3 ">
                                                                            <img src="../../../assets/images/edit_btn.png"
                                                                                alt="DoubleSpot.com" />
                                                                        </small>
                                                                        <small className="delete_btn">
                                                                            <img src="../../../assets/images/delete_btn.png"
                                                                                alt="DoubleSpot.com" />
                                                                        </small>
                                                                    </div>
                                                                </div>
                                                            </li>
                                                        </ul>
                                                    </a>
                                                </div>
                                            </div>

                                        </div>
                                    </div>

                                </div>

                            </div>

                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Profile

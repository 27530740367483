import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {getProviderVenueListing, setAlert} from "../../../../redux/action/action";
import {
    CButton,
    CCard,
    CCardBody,
    CTable,
    CTableBody,
    CTableDataCell,
    CTableHead,
    CTableHeaderCell,
    CTableRow
} from "@coreui/react";
import {useNavigate} from "react-router-dom";
import {users} from "constants";

const Venues = () => {
    document.title = "Venues | DoubleSpot Business";
    document.querySelector('meta[name="description"]').setAttribute(
        "content",
        "View and manage your event venue details with DoubleSpot's business dashboard."
    );

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const userDetail = useSelector(state => state.Apis.userDetail);
    const venueListings = useSelector(state => state.Apis.providerVenuesListing);
    const [listings, setListings] = useState([]);

    useEffect(() => {
        dispatch(getProviderVenueListing())
    }, [])

    useEffect(() => {
        setListings(venueListings);
    }, [venueListings]);

    useEffect(() => {
        if (userDetail.role) {
            if (userDetail.role !== users.ROLE_VENUE_OWNER && !userDetail.manager?.editVenueAccess) {
                dispatch(setAlert('You do not have the permissions to view the venues.','error'));
                navigate("/business/reservations/calendar");
            }
        }
    }, [userDetail]);

    return (
        <div class="p-4 w-100">
            <CCard>
                <CCardBody className="p-4">
                    <h2>Venues</h2>
                    <hr/>
                    <CTable align="middle" className="mb-0 border" hover style={{tableLayout: "fixed"}}>
                        <CTableHead className="text-nowrap">
                            <CTableRow>
                                <CTableHeaderCell className="bg-body-tertiary" style={{width: "1%"}}/>
                                <CTableHeaderCell className="bg-body-tertiary" style={{width: "35%"}}>Name</CTableHeaderCell>
                                <CTableHeaderCell className="bg-body-tertiary" style={{width: "35%"}}>Address</CTableHeaderCell>
                                <CTableHeaderCell className="bg-body-tertiary" style={{width: "10%"}}>Min. Hours</CTableHeaderCell>
                                <CTableHeaderCell className="bg-body-tertiary" style={{width: "10%"}}>Hourly Price</CTableHeaderCell>
                                <CTableHeaderCell className="bg-body-tertiary" style={{width: "8%"}}/>
                                <CTableHeaderCell className="bg-body-tertiary" style={{width: "1%"}}/>
                            </CTableRow>
                        </CTableHead>
                        <CTableBody>
                            {listings.map((listing, index) => (
                                <CTableRow v-for="item in tableItems" key={index}>
                                    <CTableDataCell/>
                                    <CTableDataCell>
                                        <div>{listing?.name}</div>
                                    </CTableDataCell>
                                    <CTableDataCell>
                                        <div>{listing?.location}</div>
                                    </CTableDataCell>
                                    <CTableDataCell>
                                        <div>{listing ? `${listing.min_hours} hr${listing.min_hours > 1 ? 's' : ''}` : 'N/A'}</div>
                                    </CTableDataCell>
                                    <CTableDataCell>
                                        <div>{listing?.process_payment ? `$${listing.hourly_price}` : 'N/A'}</div>
                                    </CTableDataCell>
                                    <CTableDataCell className="text-center">
                                        <CButton color="dark" variant="outline" href="#" onClick={() => navigate(`/business/venues/${listing?.id}`)}>Edit</CButton>
                                    </CTableDataCell>
                                    <CTableDataCell/>
                                </CTableRow>
                            ))}
                        </CTableBody>
                    </CTable>
                </CCardBody>
            </CCard>
        </div>
    );
}

export default Venues;

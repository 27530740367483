import React, { useCallback, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from 'reactstrap';
import { setAlert, signUpUser } from "../../redux/action/action";
import {
    openSignUpModal,
    openSignInModal,
    closeModal
} from "../../redux/action/accountModals";
import { jwtDecode } from "jwt-decode";
import {microsoftSignUp} from "./MicrosoftAuth";

function SignUp() {
    // For signUp modal
    const dispatch = useDispatch()
    const toggleSignUp = () => dispatch(openSignUpModal());
    const [formData, setFormData] = useState({})

    const openSignUp = useSelector(state => state.HandleModals.openSignUpModal);
    const [showPass, setShowPass] = useState(false)
    const [showConfirmPass, setShowConfirmPass] = useState(false)
    //set state for the formData
    const onChangeData = (e) => setFormData({ ...formData, [e.target.name]: e.target.value })

    const addUserData = (e) => {
        e.preventDefault()
        if (Object.keys(formData).length > 0) {
            if (formData.password != formData.confirm_password) {
                dispatch(setAlert('Password and confirm password not matched!', 'error'));
                return
            }
        }
        dispatch(closeModal())
        dispatch(signUpUser(formData))
    }

    function keyUpkeyDown(type) {
        if (type == 'c') {
            setShowConfirmPass(!showConfirmPass)
        }
        if (type == 'p') {
            setShowPass(!showPass)
        }
    }

    function handleGoogleResponse(googleUser, error) {
        const token = googleUser.credential;
        const creds = jwtDecode(token);
        creds.social_type = 1;
        creds.profile_image = creds.picture;
        dispatch(closeModal());
        dispatch(signUpUser(creds));
    }

    const googleButtonRef = useCallback(node => {
        if (node) {
            window.google.accounts.id.initialize({
                client_id: `${process.env.REACT_APP_GOOGLE_AUTHENTICATION_CLIENT_ID}.apps.googleusercontent.com`,
                callback: handleGoogleResponse
            });
            window.google.accounts.id.renderButton(node, {
                type: 'icon',
                size: 'large',
                text: 'signup_with'
            });
        }
    });

    return <>
        <li className="nav-item">
            <a className="nav-link nav-link-bg-orange text-white" onClick={toggleSignUp} data-bs-target="#signup" data-bs-toggle="modal">
                Sign up
            </a>
        </li>
        <Modal className="singInModal s_modal" isOpen={openSignUp} toggle={() => {dispatch(closeModal())}} style={{maxWidth: "1024px"}}>
            <div className="modal-header border-0 pb-0">
                <button type="button" className="btn-close" onClick={() => {dispatch(closeModal())}} data-bs-dismiss="modal" aria-label="Close"/>
            </div>
            <div className="modal-body px-lg-4 px-md-3 pb-4">
                <div className="row">
                    <div className="col-lg-6 col-md-5 d-lg-block d-none">
                        <div className="modal_side_img w-100 h-100">
                            <img src="../../../assets/images/login_img.png" alt="DoubleSpot.com" />
                        </div>
                    </div>
                    <div className="col-lg-6  d-flex justify-content-center align-items-center flex-row">
                        <div className="modal_form px-4">
                            <h2>Sign Up</h2>
                            <div className="social_modal_icons">
                                <div ref={googleButtonRef} />
                                <div style={{width: "10px"}}/>
                                <a href="#" onClick={() => microsoftSignUp(dispatch)}><img src="../../../assets/images/microsoft_icon.svg" alt="DoubleSpot.com" /></a>
                                {/*<a href="#"><img src="../../../assets/images/facebook_icon.png" alt="DoubleSpot.com" /></a>*/}
                            </div>
                            <div className="via_line">
                                <strong>or do it via E-mail</strong>
                            </div>

                            <form onSubmit={(e) => addUserData(e)}>
                                <div className="form-group">
                                    <label for="name">Name</label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        id="name"
                                        name="name"
                                        required="true"
                                        onChange={onChangeData}
                                        placeholder="John Marker" />
                                </div>
                                <div className="form-group">
                                    <label for="email">Email</label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        id="email"
                                        required="true"
                                        placeholder="john@gmail.com"
                                        name="email"
                                        onChange={onChangeData}
                                    />
                                </div>
                                <div className="form-group">
                                    <label for="password">Password</label>
                                    <input
                                        type={showPass ? "text" : "password"}
                                        className="form-control"
                                        id="password"
                                        required="true"
                                        name="password"
                                        onChange={onChangeData}
                                        placeholder="password" />
                                    <i onClick={(e) => keyUpkeyDown("p")} className="fa-regular fa-eye"></i>
                                </div>
                                <div className="form-group">
                                    <label for="confirm_password">Confirm Password</label>
                                    <input
                                        type={showConfirmPass ? "text" : "password"}
                                        className="form-control"
                                        id="confirm_password"
                                        required="true"
                                        placeholder="confirm password"
                                        name="confirm_password"
                                        onChange={onChangeData}
                                    />
                                    <i onClick={(e) => keyUpkeyDown("c")} className="fa-regular fa-eye"></i>
                                </div>

                                <div className="form-group">
                                    <label className="form-check-label checkbox-label" for="agree">
                                        <input className="form-check-input" type="checkbox" id="agree" required />
                                        &nbsp;I accept the DoubleSpot <a href="/terms"className="text-black" target="_blank"><u>Terms</u></a> and have read and acknowledged the <a href="/privacy" className="text-black" target="_blank"><u>Privacy Policy</u></a>.
                                    </label>
                                </div>

                                <div className="form-group text-center">
                                    <input
                                        type="submit"
                                        className="submit_modal"
                                        value={`Register`}
                                    />
                                </div>
                            </form>

                            <span className="d-block text-center">Already have an account? <a className="text-black"
                                onClick={
                                    () => {
                                        dispatch(openSignInModal())
                                    }} data-bs-target="#login" data-bs-toggle="modal"> <u>Login</u>
                            </a></span>

                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    </>
}

export default SignUp

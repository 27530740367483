import {useDispatch, useSelector} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useEffect, useState} from "react";
import {setAlert} from "../../../redux/action/action";
import {users} from "constants";

const ChatbotPreview = () => {
    document.title = "DoubleSpot AI Chatbot Preview | DoubleSpot Business";
    document.querySelector('meta[name="description"]').setAttribute(
        "content",
        "Preview your DoubleSpot AI chatbot with DoubleSpot's business dashboard."
    );

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const userDetail = useSelector(state => state.Apis.userDetail);

    const [chatbotId, setChatbotId] = useState('');

    useEffect(() => {
        if (userDetail.role) {
            const businessPlan = userDetail.role === users.ROLE_VENUE_OWNER ? userDetail.business_plan : userDetail.manager?.business?.business_plan;
            if (!businessPlan?.chatbotId) {
                dispatch(setAlert('Your DoubleSpot Business plan does not include AI chatbot.', 'error'));
                navigate("/business/reservations/calendar");
            } else if (userDetail.role !== users.ROLE_VENUE_OWNER && !userDetail.manager?.aiAccess) {
                dispatch(setAlert('You do not have access to DoubleSpot AI.', 'error'));
                navigate("/business/reservations/calendar");
            } else {
                setChatbotId(businessPlan.chatbotId);
            }
        }
    }, [userDetail]);

    return (
        <iframe
            src={`https://chat.doublespot.ai/chatbot-iframe/${chatbotId}`}
            width="100%"
            className="vh-100"
        />
    );
}

export default ChatbotPreview;

import {CFormInput, CFormLabel, CModal, CModalBody, CModalHeader, CModalTitle} from "@coreui/react";
import MultiSelectCheckbox from "../../../components/MultiSelectCheckbox";

const FilterModal = ({ visible, onClose, updateFilters, updateFiltersMultiselect, filters, venues, eventTypes, eventTags, acceptBookingAccess }) => {
    return (
        <CModal scrollable backdrop="static" visible={visible} onClose={onClose}>
            <CModalHeader>
                <CModalTitle>Filters</CModalTitle>
            </CModalHeader>
            <CModalBody className="business-modal-body">
                <div class="pb-3">
                    <CFormLabel htmlFor="statuses">Status</CFormLabel>
                    <MultiSelectCheckbox
                        name="statuses"
                        onChange={updateFiltersMultiselect}
                        options={acceptBookingAccess ? ['Confirmed', 'Pending', 'Internal'].map(
                                (label, index) => ({
                                    label,
                                    value: index + 1
                                })
                            ) :
                            ['Confirmed', 'Internal'].map(
                                (label, index) => ({
                                    label,
                                    value: index * 2 + 1
                                })
                            )}
                        value={filters.statuses ? filters.statuses : []}
                    />
                </div>
                <div class="pb-3">
                    <CFormLabel htmlFor="venues">Venue</CFormLabel>
                    <MultiSelectCheckbox
                        name="venues"
                        onChange={updateFiltersMultiselect}
                        options={venues.map(listing => ({
                            label: listing.name,
                            value: listing.id,
                        }))}
                        value={filters.venues ? filters.venues : []}
                    />
                </div>
                <div class="flex-row pb-3">
                    <div class="col-6 pe-2">
                        <CFormLabel htmlFor="start_date">Start Date</CFormLabel>
                        <CFormInput type="date" name="start_date" value={filters.start_date}
                                    onChange={updateFilters}/>
                    </div>
                    <div class="col-6 ps-2">
                        <CFormLabel htmlFor="end_date">End Date</CFormLabel>
                        <CFormInput type="date" name="end_date" value={filters.end_date}
                                    onChange={updateFilters}/>
                    </div>
                </div>
                <div class="pb-3">
                    <CFormLabel htmlFor="event_types">Event Type</CFormLabel>
                    <MultiSelectCheckbox
                        name="event_types"
                        onChange={updateFiltersMultiselect}
                        options={eventTypes.map(eventType => ({
                            label: eventType.name,
                            value: eventType.id,
                        }))}
                        value={filters.event_types ? filters.event_types : []}
                    />
                </div>
                <div class="pb-2">
                    <CFormLabel htmlFor="tags">Tags</CFormLabel>
                    <MultiSelectCheckbox
                        name="tags"
                        onChange={updateFiltersMultiselect}
                        options={eventTags.map(eventTag => ({
                            label: eventTag.name,
                            value: eventTag.id,
                        }))}
                        value={filters.tags || []}
                    />
                </div>
                <div className="form-check pb-3">
                    <input type="checkbox" checked={filters.any_tags}
                           className="form-check-input primary-color"
                           name="any_tags"
                           onChange={updateFilters}/>
                    <label className="check-form-label"
                           htmlFor="any_tags">Match Any Selection</label>
                </div>
                <div class="pb-3">
                    <CFormLabel>Attendees</CFormLabel>
                    <div class="flex-row align-items-center">
                        <CFormInput type="number" name="min_attendees" min="0" value={filters.min_attendees}
                                    onChange={updateFilters} style={{width: "100px"}}/>
                        &nbsp;to&nbsp;
                        <CFormInput type="number" name="max_attendees" min="0" value={filters.max_attendees}
                                    onChange={updateFilters} style={{width: "100px"}}/>
                    </div>
                </div>
                <div class="pb-3">
                    <CFormLabel>Revenue</CFormLabel>
                    <div class="flex-row align-items-center">
                        <CFormInput type="number" name="min_revenue" min="0" value={filters.min_revenue}
                                    onChange={updateFilters} style={{width: "100px"}}/>
                        &nbsp;to&nbsp;
                        <CFormInput type="number" name="max_revenue" min="0" value={filters.max_revenue}
                                    onChange={updateFilters} style={{width: "100px"}}/>
                    </div>
                </div>
                <div class="pb-3">
                    <CFormLabel htmlFor="customer_name">Customer Name</CFormLabel>
                    <CFormInput type="text" name="customer_name" value={filters.customer_name}
                                onChange={updateFilters}/>
                </div>
                <div class="pb-3">
                    <CFormLabel htmlFor="customer_phone">Customer Phone</CFormLabel>
                    <CFormInput type="text" name="customer_phone" value={filters.customer_phone}
                                onChange={updateFilters}/>
                </div>
                <div class="pb-3">
                    <CFormLabel htmlFor="customer_email">Customer Email</CFormLabel>
                    <CFormInput type="text" name="customer_email" value={filters.customer_email}
                                onChange={updateFilters}/>
                </div>
            </CModalBody>
        </CModal>
    )
};

export default FilterModal;
